.CompnaySettings {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 100px;
  max-width: 1000px;
}

.CompnaySettingsGeneralDetails {
  width: 450px;
  // height: 430px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin: 20px;
}

.CompnaySettingsGeneralDetails h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #304a50;
  flex: 1;
}

.CompnaySettingsGeneralDetails_edit {
  padding: 3px;
  cursor: pointer;
}

.CompnaySettingsGeneralDetails_details {
  width: 100%;
  padding: 1rem 0px;
}

.center.CompnaySettingsGeneralDetails_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CompnaySettingsGeneralDetails_details h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #304a50;
  padding: 2px 0px;
}

.CompnaySettingsGeneralDetails_details p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #304a50;
  padding: 7px 0px;
}

.CompnaySettingsGeneralDetails_details img {
  width: 100%;
  height: 155px;
  // margin-bottom: 15px;
  // border: 1px solid;
  // border-radius: 50%;
  object-fit: contain;
  // overflow: hidden;
}

.CompnaySettingsGeneralDetails_details .upload_img {
  width: 155px;
  height: 155px;
  border: 1px dashed #0008;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

//
.ChangePasswordModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0005;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangePasswordModal_inner {
  width: 400px;
  // height: 381px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 25px 30px;
}

.ChangePasswordModal_inner_top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ChangePasswordModal_inner_top_cancel {
  padding: 5px;
  border-radius: 5px;
  background: #ededed;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ChangePasswordModal_inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

//
.ChangeEmailModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0005;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangeEmailModal_inner {
  width: 400px;
  // height: 381px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 25px 30px;
}

.ChangeEmailModal_inner_top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ChangeEmailModal_inner_top_cancel {
  padding: 5px;
  border-radius: 5px;
  background: #ededed;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ChangeEmailModal_inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}