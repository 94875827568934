.RegisterPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  --background: url("../../assets/bg/1.jpeg") 0 0/100% 100% no-repeat;
  background-image: url("../../assets/bg/1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .RegisterPage .auth_Flex_Left {
    min-height: 90%;
    padding-top: 15px;
  }
}
