.SelectBoxSearch {
  width: 100%;
  /* min-width: 200px; */
  border-bottom: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  height: 45px;
  background-color: #fff;
  cursor: pointer;
  color: #304a50;
}
.SelectBoxSearch.active {
  border-bottom: 1px solid #2589f6;
}
.SelectBoxSearch_drop {
  position: absolute;
  top: 50px;
  left: 0px;
  background: #ffffff;
  border: 0.5px solid rgba(48, 74, 80, 0.39);
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  padding: 3px;
  z-index: 9999;
  color: #304a50;
  filter: drop-shadow(1px 6px 14px rgba(0, 0, 0, 0.25));
  max-height: 300px;
  overflow-y: auto;
}
.SelectBoxSearch_inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 7px;
}
.SelectBoxSearch_inner_Left {
  flex: 1;
}
.SelectBoxSearch_inner_Left input {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  color: #000;
}
.SelectBoxSearch_drop_item {
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  border-radius: 3px;
  cursor: pointer;
}
.SelectBoxSearch_drop_item.active {
  background-color: #e5f2ff;
}
.SelectBoxSearch_drop_item span {
  color: #2589f6;
}

/* .SelectBoxSearch_drop_item:hover {
  color: #2589f6 !important;
  background: #e6f2ff;
} */
.SelectBoxSearch_drop_item:hover p,
.SelectBoxSearch_drop_item:hover h5,
.SelectBoxSearch_drop_item:hover h6,
.SelectBoxSearch_drop_item:hover span,
.SelectBoxSearch_drop_item:hover h4,
.SelectBoxSearch_drop_item:hover h3,
.SelectBoxSearch_drop_item:hover h2,
.SelectBoxSearch_drop_item:hover h1,
.SelectBoxSearch_drop_item:hover,
.SelectBoxSearch_drop_item:hover label {
  color: #2589f6 !important;
  background: #e6f2ff !important;
}
.SelectBoxSearch_drop_item img {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain;
  margin: 0px !important;
  margin-right: 10px !important;
}
.SelectBoxSearch_inner_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
}
.SelectBoxSearch.active .SelectBoxSearch_inner_label {
  color: #2589f6;
}
.SelectBoxSearch_inner_label.filled {
  background-color: #fff;
  font-size: 10px;
  padding: 5px;
  position: absolute;
  top: -15px;
  left: 10px;
}
.SelectBoxSearch p {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
}
