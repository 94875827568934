.MainHeader {
  position: fixed;
  width: 220px;
  height: 100vh;
  background-color: #0c2a31ee;
  top: 0px;
  left: 0px;
  padding: 15px 15px 15px 15px;
  border-radius: 0px;
  z-index: 7;
  transition: 0.1s;
  overflow-y: auto;
}

.MainHeader::-webkit-scrollbar {
  width: 3px;
}
.MainHeader::-webkit-scrollbar-track {
  background: #f1f1f155;
}
.MainHeader::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}
.MainHeader::-webkit-scrollbar-thumb:hover {
  background: #000;
}

@media screen and (max-width: 1399.9px) {
  .MainHeader {
    padding: 7px 0px 7px 0px;
    width: 100px;
  }
}

@media screen and (max-width: 1199.9px) {
  .MainHeader {
    padding: 7px 0px 7px 0px;
    width: 65px;
    overflow: inherit;
  }
}

@media screen and (max-width: 768px) {
  .MainHeader {
    left: -100vw;
  }
  .MainHeader.open {
    width: 250px;
    left: 10px;
    z-index: 99;
    top: 10px;
    border-radius: 10px;
    background: #0c2a31;
  }
}

.sidebar_top {
  padding: 10px 14px;
  margin: -6.4px -6.5px -15px;
  background: #304a50;
  border-radius: 10px;
}

.MainHeader_logo {
  width: 100%;
  height: 50px;
}

.MainHeader_logo_break1 {
  display: none;
}

@media screen and (max-width: 1399.9px) {
  .MainHeader_logo {
    display: none;
    height: 30px;
    object-fit: contain;
    width: 105px !important;
  }

  .MainHeader_logo_break1 {
    display: block;
    width: 100%;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .MainHeader_logo {
    width: 55%;
    display: block;
  }
}

.MainHeader-divider {
  width: 100%;
  height: 1px;
  background-color: #fff2;
  margin: 15px 0px;
}

.navItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  font-size: 16px;
  color: #fffa !important;
  text-decoration: none !important;
  border-radius: 15px;
  background-color: #0000;
  transition: 0.1s;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
  z-index: 99;
}

.sub_menu .navItem {
  border-radius: 0px;
  font-size: 14px;
  text-align: left;
}

.navItem:hover {
  background-color: #0002;
  transition: 0.1s;
}

.navItem svg {
  margin-right: 15px;
  color: #fffa !important;
  font-size: 22px;
}

.navItem img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  opacity: 0.5;
}

.sub_menu .navItem svg {
  font-size: 18px;
}

.sub_menu .navItem.active {
  background-color: #2589f600;
  color: #fff !important;
  font-weight: bold;
  border-left: 5px solid #2589f6;
  text-align: left;
}

.navItem.active img {
  opacity: 1;
}

.navItem.active {
  background-color: #2589f6;
  color: #fff !important;
  font-weight: bold;
}

.navItem.active svg {
  color: #fff !important;
}

.navItem p {
  display: none;
}

@media only screen and (max-width: 1399.9px) {
  .navItem {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.08em;
    border-radius: 0px;
  }
  .sub_menu .navItem{
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navItem svg {
    font-size: 22px;
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .navItem img {
    margin-bottom: 5px;
    margin-right: 0px;
  }

  .navItem.active {
    font-weight: 300;
  }
  .navItem p {
    display: none;
  }
}

@media screen and (max-width: 1199.9px) {
  .navItem div {
    display: none;
  }

  .sub_menu .navItem div {
    display: block;
    text-align: left;
  }

  .navItem p {
    display: none;
    position: relative;
    width: 35px;
    height: 35px;
    margin-top: -35px;
  }

  .sub_menu .navItem p {
    display: none !important;
  }

  .navItem:hover p {
    display: block;
  }

  .navItem:hover p span {
    display: block;
    width: 200px;
    position: absolute;
    top: -2px;
    left: 32px;
    padding: 16px;
    background: #0c2a31ee;
    border-radius: 0px 10px 10px 0px;
    color: #fff;
    text-align: initial;
  }

  .navItem.active:hover p span {
    background-color:  #2589f6;
  }

  .navItem.active.subActive p {
    display: block;
  }

  .navItem.active.subActive p span {
    display: block;
    width: 200px;
    position: absolute;
    top: 0px;
    left: 32px;
    padding: 15px;
    background: #2589f6;
    border-radius: 0px 10px 10px 0px;
    color: #fff;
    text-align: initial;
  }
}

@media only screen and (max-width: 768px) {
  .navItem {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.08em;
  }

  .navItem svg {
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .navItem div {
    display: block;
  }
  .navItem:hover p {
    display: none;
  }
}

.navItem-level1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 15px 15px 50px;
  font-size: 16px;
  color: #fffa !important;
  text-decoration: none !important;
  border-radius: 15px;
  background-color: #0000;
  transition: 0.1s;
  margin-bottom: 5px;
}

.MainHeader-drop {
  background-color: #0002;
  border-radius: 15px;
  border-bottom-right-radius: 15px;
}

.navItem-level1:hover {
  background-color: #0002;
  transition: 0.1s;
}

.navItem-level1.active {
  background-color: #0002;
  transition: 0.1s;
  color: #2589f6 !important;
}

.sub_menu {
  padding: 35px 15px 15px;
  background: rgba(5, 5, 5, 0.37);
  margin: -25px -15px 0;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 1399.99px) {
  .sub_menu {
    margin: -25px 0px 0;
    z-index: 9;
    overflow-x: hidden;
    padding: 35px 5px 15px;
  }

  .sub_menu::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: #2589f6;
    position: absolute;
    top: 10px;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 1199.9px) {
  .sub_menu {
    margin: -25px 0px 0;
    z-index: 9;
    width: 180px;
    position: absolute;
    left: 65px;
    background: #0c2a31;
    padding: 35px 15px 15px;
    max-height: 260px;
  }
  .sub_menu::-webkit-scrollbar {
    width: 3px;
  }
  .sub_menu::-webkit-scrollbar-track {
    background: #f1f1f155;
  }
  .sub_menu::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }
  .sub_menu::-webkit-scrollbar-thumb:hover {
    background: #000;
  }

  .sub_menu::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: #2589f6;
    position: absolute;
    top: 10px;
    transform: rotate(45deg);
  }
}
