.statusSettingModelSection{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 36, 52, 0.4);
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    overflow-y: auto;
    .CustomerContactModal{
        width: 500px;
        min-height: 100vh;
        max-height: 100vh;
        background-color: #fff;
        border-radius: 0px;
        overflow-x: hidden;
        color: #304a50;
        position: relative;
        box-shadow: -3px 0px 8px rgb(0 0 0 / 25%);
        padding-bottom: 70px;
        overflow-y: auto;
    }
}