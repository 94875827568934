@import "../../../assets/variables";

.wrapper {
  // margin-top: 0.5rem;
  text-align: center;
  // margin-bottom: -0.5rem;

  .button {
    text-transform: uppercase;
    display: inline-block;
    font-size: 12px;
    margin: 0 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: lighten($color-font, 10%);
    }
  }

  .listItem {
    display: inline-block;
    line-height: 25px;
    width: 25px;
    text-align: center;
    margin: 5px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: normal;

    &:hover {
      background-color: $color-hover;
      color: white;
    }
  }

  .listItemActive {
    background-color: $color-primary;
    color: white;
  }
}

.table_paganation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 0 5px;
  p {
    margin: 0px 2px;
    color: #7d8d91;
    font-size: 14px;
  }
}
.paganation_select {
  margin: 0px 3px;
  background: #ededed;
  width: 35px;
  height: 22px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid #cfcfcf;
  outline: none;
}
.table_paganation svg {
  color: #7d8d91;
}
.paging-btn:hover {
  cursor: pointer;
}
.p_tag {
  margin-right: 10px !important;
}
