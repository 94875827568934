.Jobs.SelectBox {
  width: 100%;
  /* min-width: 200px; */
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 15px;
  position: relative;
  height: 45px;
  background-color: #2589f6;
  cursor: pointer;
  color: #fff;
}
.Jobs.SelectBox.active {
  border: 1px solid #2589f6;
}
.Jobs .SelectBox_drop {
  position: absolute;
  top: 50px;
  left: 0px;
  background: #ffffff;
  border: 0.5px solid rgba(48, 74, 80, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 3px;
  z-index: 9999;
  color: #304a50;
  filter: drop-shadow(1px 6px 14px rgba(0, 0, 0, 0.25));
}
.Jobs .SelectBox_inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 7px;
}
.Jobs .SelectBox_inner_Left {
  flex: 1;
}
.Jobs .SelectBox_drop_item {
  padding: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  border-radius: 15px;
}
.Jobs .SelectBox_drop_item:hover {
  color: #2589f6 !important;
  background: #e6f2ff;
}
.Jobs .SelectBox_drop_item:hover p,
.Jobs .SelectBox_drop_item:hover h5,
.Jobs .SelectBox_drop_item:hover h6,
.Jobs .SelectBox_drop_item:hover span,
.Jobs .SelectBox_drop_item:hover h4,
.Jobs .SelectBox_drop_item:hover h3,
.Jobs .SelectBox_drop_item:hover h2,
.Jobs .SelectBox_drop_item:hover h1,
.Jobs .SelectBox_drop_item:hover label {
  color: #2589f6 !important;
  background: #e6f2ff !important;
}
.Jobs .SelectBox_drop_item img {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain;
  margin: 0px !important;
  margin-right: 10px !important;
}
.Jobs .SelectBox_inner_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
}
.Jobs.SelectBox.active .SelectBox_inner_label {
  color: #2589f6;
}
.Jobs .SelectBox_inner_label.filled {
  background-color: #fff;
  font-size: 10px;
  padding: 5px;
  position: absolute;
  top: -15px;
  left: 10px;
}
.Jobs.SelectBox p {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #fff;
}
