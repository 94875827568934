.Btn{
    border: 2px solid black;
}
.Pattern{
    box-shadow:50px ;
    box-shadow: inset;
    border: 2px solid white;
    border-radius: 30px;
    
}
.btn{
    background: blue;
    color: white;
}
.First{
    box-shadow: currentColor;
    border-radius: 5px;
   
    background:white;
    color: black;

}
.p{
 color: black;
 font-weight: bold;
 text-decoration: solid;
}
.third{
    box-shadow: 100px;
    border: 3px solid white;
}