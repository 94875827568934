.SelectBox {
  width: 100%;
  /* min-width: 200px; */
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  height: 45px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  color: #304a50;
}
/* .SelectBox::after {
  content: "|";
  position: absolute;
  right: 13%;
  top: 15%;
  font-size: 20px;
} */
.SelectBox.active {
  border: 1px solid #2589f6;
}
.SelectBox_drop {
  position: absolute;
  top: 50px;
  left: 0px;
  background: #ffffff;
  border: 0.5px solid rgba(48, 74, 80, 0.39);
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  padding: 3px;
  z-index: 9999;
  color: #304a50;
  filter: drop-shadow(1px 6px 14px rgba(0, 0, 0, 0.25));
  max-height: 300px;
  overflow-y: auto;
}

.SelectBox_drop input[type="text"] {
  width: 100%;
  height: 40px;
  padding: 10px !important;
  border-radius: 7px;
  border: 1px solid #dfdfdf;
  background: #ededed !important;
  margin: 5px 1%;
}

.SelectBox_inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 7px;
}
.SelectBox_inner_Left {
  flex: 1;
}
.SelectBox_drop_item {
  padding: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  border-radius: 3px;
  padding: 7px;
}
.SelectBox_drop_item:hover {
  background-color: #e5f2ff;
  cursor: pointer;
  border-radius: 3px;
  
}
.SelectBox_drop_item:hover p,
.SelectBox_drop_item:hover h5,
.SelectBox_drop_item:hover h6,
.SelectBox_drop_item:hover span,
.SelectBox_drop_item:hover h4,
.SelectBox_drop_item:hover h3,
.SelectBox_drop_item:hover h2,
.SelectBox_drop_item:hover h1,
.SelectBox_drop_item:hover label {
  color: #2589f6 !important;
  background: #e6f2ff !important;
}
.SelectBox_drop_item img {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain;
  margin: 0px !important;
  margin-right: 10px !important;
}
.SelectBox_inner_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
}
.SelectBox.active .SelectBox_inner_label {
  color: #2589f6;
}
.SelectBox_inner_label.filled {
  background-color: #fff;
  font-size: 10px;
  padding: 5px;
  position: absolute;
  top: -11px;
  left: 10px;
}
.SelectBox p {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
}
