.JobsOverview {
  width: 100%;
  max-width: 1199px;
  height: 100%;
  background-color: #f000;
  padding: 15px;
  /* padding-left: 0; */
}

@media only screen and (max-width: 1599.9px) {
  .JobsOverview {
    padding: 15px 0;
    padding-left: 0;
    max-width: 990px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .JobsOverview {
    padding: 15px 0;
    padding-left: 0;
    max-width: 970px;
  }
}

@media only screen and (max-width: 600px) {
  .JobsOverview {
    padding: 0;
    padding-left: 0;
  }
}

.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
}

@media only screen and (max-width: 600px) {
  .PageHeader {
    flex-direction: column;
    border-bottom: 1px solid #fff2;
    padding-top: 55px;
  }
}

.PageHeader-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  color: #fff !important;
}

.PageHeader-left h2 {
  font-size: 25px;
  margin: 0px 10px;
}

.PageHeader-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.JobsOverview_body {
  width: 100%;
  background: #fff;
  padding: 0px 0px;
  min-height: 80%;
  max-height: 83%;
  border-radius: 15px;
}

.JobsOverview_body_top {
  padding: 7px 7px;
  border-bottom: 1px solid #0002;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.JobsOverview_body_top_btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #ededed;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 20px;
  cursor: pointer;
}

.JobsOverview_body_top_btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.JobsOverview_body_top_search {
  background-color: #ededed;
  border-radius: 30px;
  padding: 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 18px;
  margin: 0px 7px;
}

.JobsOverview_body_top_search input {
  border: none !important;
  background-color: #0000 !important;
  height: 35px !important;
  font-size: 14px;
  outline: none !important;
}

.JobsOverview_top_tabs {
  position: absolute;
  background: #ffffff;
  left: 50%;
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px #0002;
}

@media only screen and (max-width: 1599.9px) {
  .JobsOverview_top_tabs {
    left: 45%;
  }
}

@media only screen and (max-width: 1199.9px) {
  .JobsOverview_top_tabs {
    left: 40%;
  }
}

.JobsOverview_top_tabs_item {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.JobsOverview_top_tabs_item_active {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  background-color: #2589f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.JobsOverview_jobTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}

.JobsOverview_jobTabs_inner {
  flex: 1;
  height: 45px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.JobsOverview_jobTabs_inner_border {
  height: 35px;
  width: 1px;
  background-color: #ffffff;
  margin: 0px 5px;
}

.JobsOverview_jobTabs_inner_item {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 10px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.JobsOverview_jobTabs_inner_item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.JobsOverview_jobTabs_inner_item_active {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 15px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #304a50;
  cursor: pointer;
}

@media only screen and (max-width: 1599.9px) {
  .JobsOverview_jobTabs_inner_item_active {
    padding: 10px;
  }
}

.JobsOverview_jobTabs_inner_item_active img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.JobsOverview_jobTabs_inner_item_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.JobsOverview_jobTabs_inner_item_circle_text {
  width: 100px;
}

.JobsOverview_jobTabs_inner_item_text {
  text-align: left;
}

.JobsOverview_returnDevices {
  width: 180px;
  height: 45px;
  background: #ffffff;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0px 5px 10px #0002;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JobsOverview_returnDevices_img {
  width: 47.71px;
  height: 59px;
  margin-top: -15px;
  margin-right: 7px;
}

.JobsOverview_returnDevices_text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #4f656a;
}

.JobsOverview_returnDevices_number {
  border-radius: 50%;
  background: #304a50;
  margin: 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #ffffff;
  width: 35px;
  height: 35px;
}

.JobsOverview_returnDevices_number_text {
  width: 100px;
  text-align: center;
}

.JobsOverview_body_bottom {
  padding: 0px 15px;
  max-height: 63vh;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 10px;
}

.JobsOverview_body_bottom::-webkit-scrollbar {
  width: 2px;
}

.JobsOverview_body_bottom::-webkit-scrollbar-track {
  background: #0003;
}

.JobsOverview_body_bottom::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}

.JobsOverview_body_bottom::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

.JobsOverview_body_bottom_flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.AllTicketsPage-view-item {
  width: 100%;
  padding: 5px;
  background-color: #ededed;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #273333;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
}

.AllTicketsPage-view-item h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  color: #304a50;
  padding-bottom: 3px;
}

.AllTicketsPage-view-item p {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #304a50;
  margin: 0px;
}

.AllTicketsPage-view-item-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.AllTicketsPage-view-item-flex-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .AllTicketsPage-view-item {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    padding: 7px;
    margin: 0px 0px 10px;
  }
}

.AllTicketsPage-view-item_status {
  padding: 5px 10px;
  border-radius: 30px;
  background-color: #ff9438;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin: 0px 10px;
}

.AllTicketsPage-view-item-middle {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .AllTicketsPage-view-item-middle {
    margin-bottom: 10px;
  }
}

.AllTicketsPage-view-item-left {
  background: #fff;
  width: auto;
  border-radius: 15px;
  padding: 7px;
  border-right: 1px solid #fff;
  height: 100%;
}

.bars {
  border-right: 1px solid #0005;
  margin-right: 5px;
  flex: 1;
  padding-right: 7px;
}

.AllTicketsPage-view-item_horizontal {
  flex: 1;
  height: 1px;
  border: 1px dashed #0009;
}

.AllTicketsPage-view-item-left_chat_pp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #76fe36;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .AllTicketsPage-view-item-left {
    padding: 0px;
    border-right: 0px solid #27333322;
    border-bottom: 1px solid #27333322;
    margin-bottom: 5px;
    padding-bottom: 2px;
  }
}

.AllTicketsPage-view-item-left img {
  width: 30px;
  height: 30px;
}

.AllTicketsPage-view-item-left-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AllTicketsPage-view-item-left-top-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.AllTicketsPage-view-item-left-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.AllTicketsPage-view-item-left-bottom-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.AllTicketsPage-view-item-middle-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.AllTicketsPage-view-item-left-top-left-bold {
  font-size: 20px;
  font-weight: bold;
  margin-right: 2px;
}

@media only screen and (max-width: 600px) {
  .AllTicketsPage-view-item-left-top-left-bold {
    font-size: 12px;
  }
}

.AllTicketsPage-view-item-middle-bottom {
  color: #273333;
}

.AllTicketsPage-view-item-left-bottom-dot {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #0005;
  margin-right: 10px;
}

.AllTicketsPage-view-item-middle-left-bold {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .AllTicketsPage-view-item-middle-left-bold {
    font-size: 14px;
  }
}

.AllTicketsPage-view-item-middle-left-bage {
  margin: 0px 10px;
  padding: 3px 10px;
  border-radius: 30px;
  color: #fff;
  background-color: #ff8787;
  font-size: 12px;
}

.AllTicketsPage-view-item-middle-left-bage-blue {
  border-radius: 10px;
  padding: 5px 5px;
  background-color: #3eb2e7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.AllTicketsPage-view-item-middle-bottom span {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.AllTicketsPage-view-item-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.AllTicketsPage-view-item-right-btn {
  background-color: #0000;
  font-size: 16px;
}

.AllTicketsPage-view-item-right-progress {
  width: 75px;
  height: 50px;
  margin-right: 10px;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 30px;
}

.AllTicketsPage-top-left-tab.active .AllTicketsPage-top-left-tab-number {
  background-color: #3eb2e7;
  color: #fff !important;
}

.allTickets-filter {
  padding: 10px;
}

.allTickets-filter-select-input {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  margin: 5px 0px;
  border: 1px solid #0002;
}

.allTickets-filter-row-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.allTickets-filter-btn-blue {
  border: 1px solid #3eb2e7 !important;
  flex: 1;
  height: 40px;
  color: #3eb2e7 !important;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  margin-right: 5px;
}

.allTickets-filter-btn-secondry {
  border: 1px solid #aaa !important;
  flex: 1;
  height: 40px;
  color: #aaa !important;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  margin-left: 5px;
}

.AllTicketsPage-view-item-left-bottom-text {
  font-size: 14px;
}

.AllTicketsPage-view-item-left-bottom-right {
  font-size: 14px;
}

.EstimatedTicketsItems_cards {
  width: 225px;
  border-radius: 15px;
  border: 5px solid #d8d8d8;
  padding: 10px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.EstimatedTicketsItems_cards_circle_top {
  width: 35px;
  height: 35px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 30px 12px 30px;
  position: absolute;
  top: -7px;
  right: -7px;
}

.EstimatedTicketsItems_cards_circle_top img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.EstimatedTicketsItems_cards_flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EstimatedTicketsItems_cards_flex_center_column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.EstimatedTicketsItems_cards_logo {
  width: 30px;
  height: 30px;
}

.EstimatedTicketsItems_cards_text_bold {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #304a50;
}

.EstimatedTicketsItems_cards_text_regular {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: #304a50;
}

.EstimatedTicketsItems_cards_bg {
  background: #ededed;
  border-radius: 10px;
  padding: 5px;
  margin: 5px 0px;
}

.EstimatedTicketsItems_cards_input_wrapper {
  width: 100%;
  position: relative;
  border: 1px solid #e4e7f0;
  border-radius: 12px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
}

.EstimatedTicketsItems_cards_input {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #fff;
}

.EstimatedTicketsItems_cards_input_2 {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #ededed !important;
}

.EstimatedTicketsItems_cards_input.textArea {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #fff !important;
  height: 200px;
}

.EstimatedTicketsItems_cards_input_check {
  margin-right: 5px;
  color: #ffd369 !important;
  background-color: #fff !important;
  --background: #fff !important;
  --color: #fff !important;
  display: none;
}

.EstimatedTicketsItems_cards_input_check_label {
  width: 20px;
  height: 20px;
  border: 1px solid #a0adb3;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.EstimatedTicketsItems_cards_input_check:checked~.EstimatedTicketsItems_cards_input_check_label {
  border: 1px solid #000;
  background-color: #000;
}

.EstimatedTicketsItems_cards_input_label {
  position: absolute;
  top: 15px;
  left: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #a0adb3;
  background-color: #fff;
  padding: 1px 3px;
}

.EstimatedTicketsItems_cards_input:focus~.EstimatedTicketsItems_cards_input_label,
.EstimatedTicketsItems_cards_input:valid~.EstimatedTicketsItems_cards_label {
  top: -10px;
}

.EstimatedTicketsItems_cards_btn {
  padding: 5px 10px;
  height: 35px;
  border-radius: 30px;
  background-color: #2589f6;
  flex: 1;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.EstimatedTicketsItems_cards_btn_trans {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  background: rgba(211, 212, 218, 0.3);
  border-radius: 40px;
  color: #000;
}

.EstimatedTicketsItems_cards_hovered {
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px 0px #0003;
  background-color: #fff;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.EstimatedTicketsItems_cards_on_hover:hover~.EstimatedTicketsItems_cards_hovered {
  display: block;
}