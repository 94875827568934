.InputBoxBotton {
  width: 100%;
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  height: 45px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #304a50;
}

.InputBoxBotton.disabled {
  border: 1px solid #A0ADB3;
}
.InputBoxBotton.disabled input{
  color: #A0ADB3;
  cursor: no-drop;
}

.InputBoxBotton_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
}

.InputBoxBotton.disabled .InputBoxBotton_label {
  color: #e4e7f0;
}

.InputBoxBotton_input {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff0 !important;
  outline: none;
  color: #304a50;
  z-index: 99;
}

.InputBoxBotton_input:focus ~ .InputBoxBotton_label,
.InputBoxBotton.active .InputBoxBotton_label {
  top: -10px;
  font-size: 10px;
}

.InputBoxBotton_input_right {
  padding: 5px;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #a0adb3;
  cursor: pointer;
}
