.otherssettingsContainer {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.othersettingContant {
    padding: 60px 30px;

    .contant_headding {
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #304A50;
        }
    }

    .contant_body {

        .body_preview {
            text-align: center;

            p {
                font-weight: 500;
                font-size: 16px;
                margin: 1em 0rem;
                color: #304A50;
            }

            .select_type {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 1rem;

                .value {
                    color: #2589F6;
                    font-weight: 500;
                    font-size: 28px;
                    margin: 0px;
                }

                .data {
                    margin-bottom: 1rem;
                    text-align: left;
                    font-size: 10px;
                }

            }

        }

        .input_sections {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .input-container {
                padding: 0px !important;

                .SelectBox p {
                    width: 100%;
                    overflow: hidden;
                    margin: 0px auto !important;

                }

                .SelectBox::after {
                    right: 30% !important;
                    color: #afb9bb !important;
                    top: 10% !important;
                }

                .SelectBox_inner {
                    padding: 12px 18px;
                    width: 112px;

                    .SelectBox_inner_Left {
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                    }
                }
            }
        }

        .SelectBox_inner_right{
            display: flex !important;
            align-items: center !important;
        }
        .InputBox_label{
            font-size: 12px !important;
            line-height: 15px;
            letter-spacing: 0.07em;
            color: #304a50;
            background-color: #fff;
            position: absolute;
            left: 10px;
            padding: 3px;
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;
        }
       
        

        .checkbox_section {
            padding-top: 2rem;

            p {
                font-weight: 400;
                font-size: 15px;
                color: #304A50;
            }

            .checkbox {
                display: flex;
            }
        }

        .doc_section {
            padding-top: 2rem;

            p {
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 20px;
                color: #304A50;
            }

            .doc_input {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .inputContainer {

                    .InputBox {
                        width: 90% !important;

                        .InputBox_input {
                            color: #2589F6 !important;

                        }
                    }
                }
            }
        }
    }


}

.InvoiceSettings_container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    .InvoiceSettings_contant {
        padding: 60px 30px;


        .Invoice_para {
            font-weight: 400;
            font-size: 20px;
            padding-bottom: 1rem;

        }

        .f1 {
            h6 {
                font-weight: 700;
                font-size: 13px;
                color: #304A50;
            }

            p {
                font-weight: 400;
                font-size: 13px;
                color: #304A50;
            }

        }
    }
}

.SelectBox_drop input[type="text"] {
    padding: 0px !important;
}

.input-container .InputBox {
    margin-top: 3px;
}

.Managed_container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    .Managed_contant {
        padding: 40px 30px;

        h6 {
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 0.8rem;
            color: #304A50;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            color: #304A50;
        }

        .Managed_tags_contant {
            .tag_box {
                padding-top: 1rem;

                .tagInput {
                    padding: 13px 0px;
                    width: 720px !important;
                    flex-wrap: wrap;
                }

                h6 {
                    font-weight: 400;
                    font-size: 16px;
                    color: #304A50;
                }
            }
            .tagInput_input{
                box-sizing: border-box;
                border: 0px solid #e4e7f0;
                border-radius: 3px;
                max-width: auto !important;
                min-width: auto !important;
                 height: auto !important;
                padding: 10px;
                background-color: #fff !important;
                outline: none;
                color: #304a50;
                z-index: 99;
                width: 720px !important;
            }
            .tagInput_input_right{
                margin-top: 5px !important;
            }
        }
    }
}