.searchDropDown {
  position: relative;
  display: flex;
  flex-direction: column;
  .input {
    border: none;
    // padding: 0 10px;
    width: 95%;
    height: 95%;

    &:focus-visible {
      outline: none;
    }
  }
  .drop {
    display: none !important;
    position: absolute;
    border: 1px solid #afb9bb;
    width: 100%;
    top: 110%;
    z-index: 100;
    border-radius: 2px;
    padding: 0 5px 5px;
    background-color: #ffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 3px;
  }

  .input:focus + .drop {
    display: block !important;
  }

  .input:focus ~ .drop {
    display: block !important;
  }
  .input:focus ~ .InputBox_label,
  .InputBox.active .InputBox_label {
    top: -10px;
    font-size: 10px;
    z-index: -1;
  }

  .InputBox_label {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.07em;
    color: #304a50;
    background-color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 3px;
  }
  .option {
    cursor: pointer;
    margin: 2px 0;
    padding: 5px 9px;
    font-size: 14px;
    border-radius: 3px;

    &:hover {
      background-color: #e5f2ff;
    }
  }

  .AddOption {
    cursor: pointer;
    margin: 2px 0;
    padding: 3px 9px;
    font-size: 14px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #e5f2ff;
    }

    .addBtn {
      background-color: #2589f6;
      padding: 5px 10px;
      border-radius: 3px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
