.Invoice {
  width: 100%;
  max-width: 1199px;
  height: 100%;
  background-color: #f000;
  padding: 15px;
  /* padding-left: 0; */
}

@media only screen and (max-width: 1599.9px) {
  .Invoice {
    padding: 15px 0;
    padding-left: 0;
    max-width: 990px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .Invoice {
    padding: 15px 0;
    padding-left: 0;
    max-width: 970px;
  }
}

@media only screen and (max-width: 600px) {
  .Invoice {
    padding: 0;
    padding-left: 0;
  }
}

.Invoice_body {
  width: 100%;
  background: #fff;
  padding: 0px 0px;
  min-height: 80vh;
  max-height: 80vh;
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .Invoice_body {
    min-height: 75vh;
    max-height: 75vh;
    margin-bottom: 55px;
  }
}

.Invoice_body_top {
  padding: 7px 7px;
  border-bottom: 1px solid #0002;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Invoice_body_top_btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .Invoice_body_top_btn {
    width: 66px;
    height: 26px;
    padding: 7px;
  }
}

.Invoice_body_top_btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.Invoice_body_top_search {
  background-color: #fff;
  border-radius: 30px;
  padding: 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 18px;
  margin: 0px 7px;
  border: 1px solid #304a5030;
}

.ConvertTicket_modal_search.Invoice_body_top_search {
  font-size: 15px;
}

.Invoice_body_top_search input {
  border: none !important;
  background-color: #0000 !important;
  height: 35px !important;
  font-size: 14px;
  outline: none !important;
}

.ConvertTicket_modal_search.Invoice_body_top_search input {
  height: 25px !important;
  font-size: 12px;
}

.Invoice_top_tabs {
  position: absolute;
  background: #ffffff;
  left: 50%;
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px #0002;
  margin-top: -55px;
}

@media only screen and (max-width: 1599.9px) {
  .Invoice_top_tabs {
    left: 45%;
  }
}

@media only screen and (max-width: 1199.9px) {
  .Invoice_top_tabs {
    left: 40%;
  }
}

.Invoice_top_tabs_item {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.Invoice_top_tabs_item_active {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  background-color: #2589f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.Invoice_jobTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 20px;
}

.Invoice_jobTabs_inner {
  flex: 1;
  height: 45px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(0px);
  border-radius: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .Invoice_jobTabs_inner {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.Invoice_jobTabs_mobile_scroll {
  /* background-color: #000; */
  width: calc(100vw - 20px);
  /* padding: 10px; */
  /* margin-right: 13px; */
  overflow-x: auto;
}

.Invoice_jobTabs_inner_border {
  height: 35px;
  width: 1px;
  background-color: #ffffff;
  margin: 0px 5px;
}

.Invoice_jobTabs_inner_item {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 10px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.Invoice_jobTabs_inner_item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Invoice_jobTabs_inner_item_active {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 15px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #304a50;
  cursor: pointer;
}

@media only screen and (max-width: 1599.9px) {
  .Invoice_jobTabs_inner_item_active {
    padding: 10px;
  }
}

.Invoice_jobTabs_inner_item_active img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Invoice_jobTabs_inner_item_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.Invoice_jobTabs_inner_item_circle_text {
  width: 100px;
}

.Invoice_jobTabs_inner_item_text {
  text-align: left;
}

.Invoice_returnDevices {
  width: 180px;
  height: 45px;
  background: #ffffff;
  backdrop-filter: blur(0px);
  border-radius: 15px;
  box-shadow: 0px 5px 10px #0002;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Invoice_returnDevices_img {
  width: 47.71px;
  height: 59px;
  margin-top: -15px;
  margin-right: 7px;
}

.Invoice_returnDevices_text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #4f656a;
}

.Invoice_returnDevices_number {
  border-radius: 50%;
  background: #304a50;
  margin: 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #ffffff;
  width: 35px;
  height: 35px;
}

.Invoice_returnDevices_number_text {
  width: 100px;
  text-align: center;
}

.Invoice_body_bottom {
  padding: 0px 15px;
  max-height: 74vh;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 10px;
  min-height: 74.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .Invoice_body_bottom {
    max-height: 54vh;
    min-height: 45vh;
    padding: 0px;
  }
}

.Invoice_body_bottom::-webkit-scrollbar {
  width: 2px;
}
.Invoice_body_bottom::-webkit-scrollbar-track {
  background: #0003;
}
.Invoice_body_bottom::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}
.Invoice_body_bottom::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

.Invoice_body_bottom_flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

//

.InvoiceItems {
  width: 100%;
  padding: 5px;
  background-color: #ededed;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #273333;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
}

.InvoiceItems_left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 5px 5px;
}

.InvoiceItems_left img {
  width: 50px;
  height: 40px;
  object-fit: contain;
}

.InvoiceItems_left p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7d8d91;
  position: relative;
  padding-bottom: 2px;
}

.InvoiceItems_left p span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #fe7c03;
  position: absolute;
  bottom: 0;
  right: 0;
}

.InvoiceItems_left h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
  }
}

.InvoiceItems_left_1 {
  flex: 2;
  // border-right: 0.5px solid #304a50;
}

.InvoiceItems_left_2 {
  border-right: 0.5px solid #304a50;
  margin-right: 7px;
}

.InvoiceItems_left_2,
.InvoiceItems_left_3 {
  flex: 1;
}

.InvoiceItems_left_4 {
  flex: 1.5;
}

.InvoiceItems_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.InvoiceItems_right h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #304a50;
  border-radius: 10px;
  padding: 3px;
  margin: 0px 15px;
  min-width: 90px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  span {
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    font-weight: normal;
  }
}

.InvoiceItems_right h6 {
  background: #ff0000;
  border-radius: 10px;
  padding: 3px;
  margin: 0px 15px;
  min-width: 90px;
  margin-top: 3px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.InvoiceItems_right p {
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #304a50;
  margin: 0px 15px;
  min-width: 90px;
  text-align: center;
}

//
.InvoiceModal {
  position: fixed;
  top: 0;
  left: 220px;
  width: calc(100% - 220px);
  height: 100%;
  background: #f0f0f0;
  backdrop-filter: blur(0px);
  z-index: 99;
  // display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;
  overflow-y: auto;

  @media only screen and (max-width: 1399px) {
    left: 100px;
    width: calc(100% - 100px);
  }

  @media only screen and (max-width: 1199px) {
    left: 65px;
    width: calc(100% - 65px);
  }

  &_header {
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_back {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      border-right: 0.4px solid #d1d1d1;
      cursor: pointer;
    }

    &_Title {
      padding: 15px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.01em;
      color: #304a50;
    }
  }

  &_body {
    width: 100%;
    max-height: calc(100% - 70px);
    background-color: #0001;
    padding-top: 50px;
    overflow-y: auto;

    &_inner {
      width: 800px;
      min-height: calc(100vh - 120px);
      background: #ffffff;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
      margin: 0px auto;
      padding: 50px;

      &_logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          width: 200px;
          height: 100px;
          object-fit: contain;
        }
      }

      &_details {
        width: 100%;
        padding: 20px 0px;

        small {
          display: block;
        }

        .bt-dotted {
          padding: 10px;
          min-width: 100%;
          margin: 10px 0px;
          border-radius: 7px;
          border: 1px dashed;
          background: #fff;
          color: #304a50;

          &:hover {
            border: 1px dashed #2589f6;
            color: #2589f6;
          }

          &.drop {
            padding: 10px;
            min-width: 183px;
            margin: 10px 0px;
            border-radius: 7px;
            border: 1px dashed;
            background: #fff;
            height: 41px;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &_stocks {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.01em;
          color: #304a50;
          text-align: right;
        }

        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.01em;
          color: rgba(39, 51, 51, 0.5);
          text-align: right;
        }
      }

      &_tabs {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0px;

        button {
          padding: 10px;
          border-radius: 7px;
          background-color: #fff;
          border: 1px solid #304a50;

          &:nth-child(1) {
            border-radius: 7px 0px 0px 7px;
          }

          &:nth-child(2) {
            border-radius: 0px 7px 7px 0px;
          }

          &.active {
            color: #fff;
            background-color: #4f656a;
          }
        }
      }

      &_table {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        position: relative;

        &.row_align_top {
          align-items: flex-start;
        }

        .start {
          position: absolute;
          left: -30px;
          cursor: pointer;
        }

        .end {
          position: absolute;
          right: -25px;
          cursor: pointer;
        }

        &.header {
          background: #697c80;
          border-radius: 5px;
        }

        &_col {
          flex: 1;
          padding: 0px 10px;
          position: relative;
          // min-width: 115px;

          &_2 {
            flex: 2;
            padding: 0px 10px;
            position: relative;
            // min-width: 230px;

            .bt-dotted {
              padding: 10px;
              min-width: 100%;
              margin: 10px 0px;
              border-radius: 7px;
              border: 1px dashed;
              background: #fff;

              &:hover {
                border: 1px dashed #2589f6;
                color: #2589f6;
              }

              &.drop {
                padding: 10px;
                min-width: 183px;
                margin: 10px 0px;
                border-radius: 7px;
                border: 1px dashed;
                background: #fff;
                height: 41px;
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          &_3 {
            flex: 4.5;
            padding: 0px 10px;
            position: relative;
          }
        }

        &_whiteText {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.01em;
          color: #ffffff;
        }

        &_text {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.01em;
        }

        .form-control {
          width: 100%;
          background: #ededed !important;
          border-radius: 7px;
          border: 1px solid #ededed;
          height: 40px;
          padding: 0px 10px;
          outline: none;
        }

        .blueBox {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #2589f6;
          border-radius: 7px;
          margin-top: 12px;
          color: #fff;
          cursor: pointer;
        }
      }

      &_subBox {
        width: 100%;
        padding: 0px 10px;
        border-radius: 7px;
        background-color: #ededed;

        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.01em;
          color: #304a50;
          padding: 0px;

          &.blueText {
            color: #2589f6;
          }

          &.underline {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
