.Subcriptions {
  width: 100%;
  max-width: 1199px;
  height: 100%;
  background-color: #f000;
  padding: 15px;
  /* padding-left: 0; */
}

@media only screen and (max-width: 1599.9px) {
  .Subcriptions {
    padding: 15px 0;
    padding-left: 0;
    max-width: 990px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .Subcriptions {
    padding: 15px 0;
    padding-left: 0;
    max-width: 970px;
  }
}

@media only screen and (max-width: 600px) {
  .Subcriptions {
    padding: 0;
    padding-left: 0;
  }
}

.Subcriptions_body {
  width: 100%;
  background: #fff;
  padding: 0px 0px;
  min-height: 74vh;
  max-height: 74vh;
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .Subcriptions_body {
    min-height: 60vh;
    max-height: 60vh;
    margin-bottom: 55px;
  }
}

.Subcriptions_body_top {
  padding: 7px 7px;
  border-bottom: 1px solid #0002;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Subcriptions_body_top_btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #ededed;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .Subcriptions_body_top_btn {
    width: 66px;
    height: 26px;
    padding: 7px;
  }
}

.Subcriptions_body_top_btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.Subcriptions_body_top_search {
  background-color: #ededed;
  border-radius: 30px;
  padding: 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 18px;
  margin: 0px 7px;
}

.ConvertTicket_modal_search.Subcriptions_body_top_search {
  font-size: 15px;
}

.Subcriptions_body_top_search input {
  border: none !important;
  background-color: #0000 !important;
  height: 35px !important;
  font-size: 14px;
  outline: none !important;
}

.ConvertTicket_modal_search.Subcriptions_body_top_search input {
  height: 25px !important;
  font-size: 12px;
}

.Subcriptions_top_tabs {
  position: absolute;
  background: #ffffff;
  left: 50%;
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px #0002;
  margin-top: -55px;
}

@media only screen and (max-width: 1599.9px) {
  .Subcriptions_top_tabs {
    left: 45%;
  }
}

@media only screen and (max-width: 1199.9px) {
  .Subcriptions_top_tabs {
    left: 40%;
  }
}

.Subcriptions_top_tabs_item {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.Subcriptions_top_tabs_item_active {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  background-color: #2589f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.Subcriptions_SubscriptionsTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 20px;
}

.Subcriptions_SubscriptionsTabs_inner {
  flex: 1;
  height: 45px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(0px);
  border-radius: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .Subcriptions_SubscriptionsTabs_inner {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.Subcriptions_SubscriptionsTabs_mobile_scroll {
  /* background-color: #000; */
  width: calc(100vw - 20px);
  /* padding: 10px; */
  /* margin-right: 13px; */
  overflow-x: auto;
}

.Subcriptions_SubscriptionsTabs_inner_border {
  height: 35px;
  width: 1px;
  background-color: #ffffff;
  margin: 0px 5px;
}

.Subcriptions_SubscriptionsTabs_inner_item {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 10px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.Subcriptions_SubscriptionsTabs_inner_item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Subcriptions_SubscriptionsTabs_inner_item_active {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 15px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #304a50;
  cursor: pointer;
}

@media only screen and (max-width: 1599.9px) {
  .Subcriptions_SubscriptionsTabs_inner_item_active {
    padding: 10px;
  }
}

.Subcriptions_SubscriptionsTabs_inner_item_active img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Subcriptions_SubscriptionsTabs_inner_item_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.Subcriptions_SubscriptionsTabs_inner_item_circle_text {
  width: 100px;
}

.Subcriptions_SubscriptionsTabs_inner_item_text {
  text-align: left;
}

.Subcriptions_returnDevices {
  width: 180px;
  height: 45px;
  background: #ffffff;
  backdrop-filter: blur(0px);
  border-radius: 15px;
  box-shadow: 0px 5px 10px #0002;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Subcriptions_returnDevices_img {
  width: 47.71px;
  height: 59px;
  margin-top: -15px;
  margin-right: 7px;
}

.Subcriptions_returnDevices_text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #4f656a;
}

.Subcriptions_returnDevices_number {
  border-radius: 50%;
  background: #304a50;
  margin: 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #ffffff;
  width: 35px;
  height: 35px;
}

.Subcriptions_returnDevices_number_text {
  width: 100px;
  text-align: center;
}

.Subcriptions_body_bottom {
  padding: 0px 15px;
  max-height: 63vh;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .Subcriptions_body_bottom {
    max-height: 54vh;
    min-height: 45vh;
    padding: 0px;
  }
}

.Subcriptions_body_bottom::-webkit-scrollbar {
  width: 2px;
}
.Subcriptions_body_bottom::-webkit-scrollbar-track {
  background: #0003;
}
.Subcriptions_body_bottom::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}
.Subcriptions_body_bottom::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

/*  */

.SubscriptionsItem {
  width: 100%;
  padding: 5px;
  background-color: #ededed;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #273333;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
}

.SubscriptionsItem_left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 5px 5px;
}

.SubscriptionsItem_left img {
  width: 50px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
}

.SubscriptionsItem_left img.small {
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 30px;
}

.SubscriptionsItem_left p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7d8d91;
  position: relative;
  padding-bottom: 2px;
}

.SubscriptionsItem_left p span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #fe7c03;
  position: absolute;
  bottom: 0;
  right: 0;
}

.SubscriptionsItem_left h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
}

.SubscriptionsItem_left_2 {
  flex: 0.7;
  border-right: 0.5px solid #304a50;
  margin-right: 7px;
}

.SubscriptionsItem_left_1 {
  flex: 0.6;
}

.SubscriptionsItem_left_3,
.SubscriptionsItem_left_5,
.SubscriptionsItem_left_6 {
  flex: 1;
}

.SubscriptionsItem_left_4 {
  flex: 1.5;
}

.SubscriptionsItem_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.SubscriptionsItem_right svg {
  font-size: 18px !important;
  margin-right: 0.5rem;
}

.SubscriptionsItem_right h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #304a50;
  border-radius: 10px;
  background: #304a50;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 0px 15px;
  min-width: 90px;
}

.SubscriptionsItem_right p {
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #304a50;
  margin: 0px 15px;
  min-width: 90px;
  text-align: center;
}
