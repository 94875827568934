.JobModal_container {
  width: calc(100%);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(20, 36, 52, 0.95);
  backdrop-filter: blur(0px);
  padding: 0px 0px 0px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.JobModal_container.d-none {
  display: none;
}

.JobModal_container .close {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: #EDEDED;
  border-radius: 7px;
  
}
.JobModal_container .Back {
  /* width: 30px;
  height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  border-radius: 7px;
 
  color: rgba(237, 237, 237, 1) !important;
  
}
.JobModal_container .Back svg{
  font-size: 40px;
  cursor: pointer;
}
.Back span{
  margin-left: 13px;
  font-size: 16px;
}


.JobModal_body {
  width: 1100px;
  height: 100vh;
  background-color: #fff0;
}

.JobModal_top {
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.JobModal_top_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  position: relative;
}

.JobModal_top_item_icon {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5d696c00;
  margin-bottom: 10px;
  position: relative;
}

.JobModal_top_item_icon svg {
  z-index: 10;
  font-size: 25px;
}

.JobModal_top_item_icon::before {
  content: "";
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #5d696c;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}

.JobModal_top_item_icon.active::after {
  content: "";
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #2589f6;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
  border: 2px solid rgba(0, 0, 0, 1);
}

.JobModal_top_item_icon.active::before {
  width: 55px;
  height: 55px;
  background-color: #2589f6;
}

.JobModal_top_item_icon.prev::before {
  content: "";
  display: flex;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #2589f6;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}

.JobModal_top_item p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  white-space: nowrap;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.JobModal_top_item p.prev {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #2589f6;
}

.JobModal_top_item p.active {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #2589f6;
}

.JobModal_top_item_line {
  width: 100%;
  height: 1px;
  margin-top: -2%;
  background: rgba(238, 238, 238, 0.2);
}

.JobModal_top_item_line.prev {
  background: #2589f6;
}

.JobModal_top_item_line.active {
  background: #2589f6;
}

.JobModal_bottom {
  flex: 1 1;
  display: flex;
  justify-content: center;
  height: 85%;
  background-color: #0000;
  overflow-y: auto;
}

.s1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.s1 h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 45px;
  margin: 30px 0px;
  color: #ffffff;
}

.s1-flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.s1-item {
  flex: 1;
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
  width: 210px;
}

.s1-item.spcl {
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  height: 273px;
  margin-top: -20px;
}

.s9-item {
  flex: 1;
  padding: 20px;
  background: rgba(12, 42, 49, 0.12);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #304a5050;
  position: relative;
}
.activePrint{
  border: 2.5px solid #2589F6;
  border-radius: 5px;
}

.s1-item_icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(12, 42, 49, 0.12);
  margin-bottom: 10px;
  position: relative;
  color: #2589f6;
}

.s9-item_icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffff;
  margin-bottom: 10px;
  position: relative;
  color: #2589f6;
}
.activePrint .s9-item_icon{
  cursor: pointer;
  background: #2589f6;
  color: #fff;
}

.s1-item_icon svg {
  font-size: 60px;
}

.s9-item_icon svg {
  font-size: 40px;
}
.check-active{
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.check-active svg{
  color: #2589f6;
  font-size: 20px;
}
.activePrint .check-active{
  display: block;
}

.s1-item p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.s1-item h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: #ff9438;
  border-radius: 18px;
  padding: 3px 10px;
  margin-top: 15px;
}

.stepFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1100px;
  background-color: #d2d4d7;
  border-radius: 10px;
  overflow: hidden;
}

.stepFormWrapper-left {
  flex: 1;
  width: 400px;
  background-color: #d2d4d7;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #304a50;
  min-height: 500px;
}

.stepFormWrapper-right {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  color: #304a50;
  min-height: 500px;
}

.stepFormWrapper-right-search_lg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #304a5050;
  padding: 1px 5px;
}

.stepFormWrapper-right-search {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #304a5050;
  padding: 1px 5px;
  padding: 5px;
  height: 35px;
}

.stepFormWrapper-right-search_lg input {
  border: none !important;
  outline: none !important;
  flex: 1;
  height: 40px;
}

.stepFormWrapper-right-search_lg_btn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  /* background-color: aqua; */
}

.stepFormWrapper-right_item_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 300px;
  flex-wrap: wrap;
}

.stepFormWrapper-right_item {
  height: 33%;
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #0003;
  border-radius: 5px;
  padding: 7px;
  cursor: pointer;
  margin: 7px 7px;
}

.stepFormWrapper-right_item img {
  width: 50px;
  margin-bottom: 10px;
}

.stepFormWrapper-right_item h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #0c2a31;
}

.JobModal-btn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2589f6;
  cursor: pointer;
border-radius: 10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
}

.JobModal-btn.bt-primary {
  color: #fff;
}
.bt-gray-btn{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background: #4AB754;
border-radius: 10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
}
.btn-gray-active{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E2E5E6;
  cursor: pointer;
  background: #FFFFFF;
border: 2.5px solid #D2D5D8;
border-radius: 10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
}

.JobModal_row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.col {
  flex: 1;
  padding: 1px 10px;
}

.stepFormWrapper-right_tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.stepFormWrapper-right_tab-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  border-bottom: 5px solid #fff;
}

.stepFormWrapper-right_tab-item.active {
  border-bottom: 5px solid #2589f6;
  color: #2589f6;
}

.JobModal_spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.JobModal_spaced h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #304a50;
}

.JobsOverview_body_top_search {
  /* background-color: #ededed; */
  border-radius: 30px;
  padding: 0px 3px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 18px;
  margin: 0px 7px;
}

.JobModal_search.JobsOverview_body_top_search {
  font-size: 15px;
}
.blue_btn {
  background-color: #2589f6 !important;
  color: #ffff !important;
}
.searchIcon {
  border-radius: 50%;
  background-color: #ffff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px !important;
  margin-right: 5px !important;
}

.JobsOverview_body_top_search input {
  border: none !important;
  /* background-color: #0000 !important; */
  height: 35px !important;
  font-size: 14px;
  outline: none !important;
  background-color: #ededed !important;
}

.JobModal_search.JobsOverview_body_top_search input {
  height: 25px !important;
  font-size: 12px;
}

.JobModal_item {
  padding: 7px;
  background: #697c80;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.JobModal_item_left {
  flex: 1;
}
.JobModal_item_left h5 {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #ffffff;
}
.JobModal_item_left h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #ffffff;
  /* flex: 1; */
  border-right: 1px solid #fff;
  margin-right: 7px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .JobModal_item_left h6 {
    width: 45%;
  }
}

.JobModal_item_right {
  width: 85px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.botn {
  padding: 3px;
  color: #304a50;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 3px;
  font-size: 10px;
}

.JobModal_item_right_Reserved {
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  background: #f88b26;
  border-radius: 3px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JobModal-bodyInner-flex-start {
  display: flex;
}

.JobModal_upload {
  width: 100%;
  padding: 50px;
  border-radius: 10px;
  border: 1px dashed #2589f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 50px;
}

.level2 {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.Modalsearch {
  padding: 5px 10px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modalsearch input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(39, 51, 51, 0.6);
  width: 229px;
  height: 40px;
  padding: 0px 10px;
  border: none;
  outline: none;
}

.Modalsearch svg {
  width: 20px;
  height: 20px;
  font-size: 50px;
}

.ServicesItemsWrapper{
  overflow-y: auto;
  padding-right: 10px;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #7e92a9;
  border-radius: 10px;
  padding-left: 10px;
}

.pricingServicesCard {
  padding: 10px;
  position: relative;
  background: #ffffff;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
}

.pricingServicesCard img {
  width: 116px;
  height: 116px;
  margin-right: 15px;
}

.pricingServicesCard-details {
  width: 300px;
  height: 115px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.pricingServicesCard-details h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
}

.pricingServicesCard-details p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  margin-bottom: 10px;
  margin-top: 7px;
  display: block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pricingServicesCard-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pricingServicesCard-price {
  width: 90px;
  height: 35px;
  background: rgba(131, 247, 76, 0.31);
  border-radius: 3px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #007c0b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pricingServicesCard-price span {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #007c0b;
}

.pricingServicesCard-btnPrimary {
  width: 150px;
  height: 35px;
  background: #2589f6;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.pricingServicesCard-btnGreen {
  width: 150px;
  height: 35px;
  background: #4ab754;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.pricingServicesCard-btnGreen svg {
  margin-left: 10px;
}

.pricingServicesCard-btnContinue {
  width: 449px;
  height: 50px;
  background: #2589f6;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.07em;
  color: #ffffff;
  margin-top: 60px;
  cursor: pointer;
}

.stepFormWrapper-left.start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.stepFormWrapper.start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
