.tagInput {
  width: 100%;
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #304a50;
  flex-wrap: wrap;
}

.tagInput.disabled {
  border: 1px solid #e4e7f0;
}

.tagInput_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
}

.tagInput.disabled .tagInput_label {
  color: #e4e7f0;
}

.tagInput_input {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 3px;
  max-width: 100%;
  min-width: 100px;
  height: 40px;
  padding: 10px;
  background-color: #fff !important;
  outline: none;
  color: #304a50;
  z-index: 99;
  width: 100%;
}

.tagInput_input:focus ~ .tagInput_label,
.tagInput.active .tagInput_label {
  top: -10px;
  font-size: 10px;
}

.tagInput_input_right {
  padding: 5px;
  background: #d8e7ff;
  border-radius: 3px 3px 3px 3px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  color: #2589f6;
  margin-left: 5px;
}

.tagInput_input_right_close {
  font-size: 16px;
  margin-bottom: -5px;
  margin-left: 5px;
  cursor: pointer;
}
