.DetailModal {
  width: calc(100% - 220px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f0f0f0;
  padding: 15px 0px 2px;
  z-index: 99;
}

@media only screen and (max-width: 1399.9px) {
  .DetailModal {
    width: calc(100% - 100px);
  }
}

@media only screen and (max-width: 1199.9px) {
  .DetailModal {
    width: calc(100% - 65px);
  }
}

@media screen and (max-width: 768px) {
  .DetailModal {
    padding: 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100%);
    z-index: 999;
  }
}

.DetailModal_body {
  /* width: 1135px; */
  height: 100%;
  background: #e4e9ec00;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); */
  border-radius: 15px;
  padding: 10px 0px 0px;
  overflow-y: auto;
  letter-spacing: 0.01em;
  color: #273333;
  font-size: 14px;
  margin: 0px auto;
  position: relative;
}
/* 
@media only screen and (max-width: 1599.9px) {
  .DetailModal_body {
    width: 1000px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .DetailModal_body {
    width: 915px;
  }
} */

@media screen and (max-width: 600px) {
  .DetailModal_body {
    padding: 0px;
    background: #e4e9ec00;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0px;
  }
}

.DetailModal_body::-webkit-scrollbar {
  width: 2px;
}
.DetailModal_body::-webkit-scrollbar-track {
  background: #0000;
}
.DetailModal_body::-webkit-scrollbar-thumb {
  background: #3331;
  border-radius: 10px;
}
.DetailModal_body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DetailModal-view-item {
  width: 100%;
  padding: 0px 10px 10px;
  background-color: rgba(39, 51, 51, 0);
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #273333;
  /* cursor: pointer; */
  margin: 10px 0px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .DetailModal-view-item {
    flex-direction: column;
    align-items: flex-start;
    background: #e4e9ec;
    border-radius: 5px;
    font-size: 12px;
    margin: 0px 0px 5px;
  }
}

.DetailModal-view-item-middle {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .DetailModal-view-item-middle {
    flex: 1;
    border-right: 0px solid rgba(0, 0, 0, 0.07);
  }
}

.DetailModal-view-item-left {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  width: 300px;
  padding-right: 15px;
  border-right: 1px solid #27333322;
}

@media only screen and (max-width: 600px) {
  .DetailModal-view-item-left {
    border-right: 0px solid #27333322;
    border-bottom: 1px solid #27333322;
    width: 280px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

.DetailModal-view-item-left-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DetailModal-view-item-left-top-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-view-item-left-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.DetailModal-view-item-left-bottom-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-view-item-middle-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.DetailModal-view-item-left-top-left-bold {
  font-size: 20px;
  font-weight: bold;
  margin-right: 2px;
}

@media only screen and (max-width: 600px) {
  .DetailModal-view-item-left-top-left-bold {
    font-size: 14px;
  }
}

.DetailModal-view-item-middle-bottom {
  color: #273333;
}

.DetailModal-view-item-left-bottom-dot {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #0005;
  margin-right: 10px;
}

.DetailModal-view-item-middle-left-bold {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.DetailModal-view-item-middle-left-bage {
  margin: 0px 10px;
  padding: 3px 10px;
  border-radius: 30px;
  color: #fff;
  background-color: #ff8787;
  font-size: 12px;
}

.DetailModal-view-item-middle-left-bage-blue {
  border-radius: 10px;
  padding: 5px 5px;
  background-color: #3eb2e7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.DetailModal-view-item-middle-bottom span {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.DetailModal-view-item-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.DetailModal-view-item-right-btn {
  background-color: #0000;
  font-size: 16px;
}

.DetailModal-top-left-tab.active .DetailModal-top-left-tab-number {
  background-color: #3eb2e7;
  color: #fff !important;
}

.btn-chat {
  margin: 0px 10px;
  background-color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 16px;
}

.btn-close {
  margin: 0px 0px;
  background-color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 20px;
  color: #0c2a31;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 15px;
  right: 15px;
}

@media screen and (max-width: 600px) {
  .btn-close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 14px;
  }
}

.btn-chat span,
.btn-close span {
  /* margin-left: 5px; */
}

.DetailModal-main {
  width: 100%;
  height: 100%;
  background-color: #0000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main {
    position: relative;
    width: 100vw;
    /* background: #f005; */
    height: 110%;
  }
}

.DetailModal-main-left,
.DetailModal-main-right {
  width: 20%;
  background-color: #fff0;
  padding: 5px 5px;
  height: 100%;
}

.DetailModal-main-left {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  padding: 7px;
}

.DetailModal-main-right {
  border-left: 1px solid rgba(0, 0, 0, 0);
  padding: 10px;
}

.DetailModal-main-middle {
  /* flex: 1; */
  background-color: #fff0;
  height: calc(100% - 0px);
  padding: 7px 10px 0px;
  /* overflow: hidden; */
  /* box-shadow: 0px 0px 17px -13px #0005; */
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle {
    width: 100vw;
    height: 85vh;
    /* margin-left: 127px; */
    /* background-color: #00000030; */
    margin: 0px;
    padding: 0;
  }
}

.DetailModal-card {
  background-color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 7px;
  margin-bottom: 5px;
}

.DetailModal-card.modal {
  width: 100%;
  padding: 0px;
  background-color: #fff;
  position: fixed;
  bottom: 50px;
  height: 280px;
  z-index: 999;
  border-radius: 0px;
}

.DetailModal-card.modal .DetailModal-card-body {
  max-height: 90%;
}

.DetailModal-card-modalbefore {
  width: 100vw;
  height: 100vh;
  background: rgba(20, 36, 52, 0.66);
  backdrop-filter: blur(0px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.DetailModal-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .modal_header.DetailModal-card-header {
    padding: 5px 10px;
    background: #1a3c43;
    color: #fff;
  }
}

.DetailModal-card-header h6 {
  font-weight: 900;
  font-size: 13px;
}

.DetailModal-card-body {
  padding: 5px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  max-height: 160px;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .DetailModal-card-body {
    padding: 10px;
  }
}

.DetailModal-card-body::-webkit-scrollbar {
  width: 2px;
}
.DetailModal-card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.DetailModal-card-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.DetailModal-card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DetailModal-card-body p {
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .DetailModal-card-body p {
    padding-bottom: 0;
  }
}

.DetailModal-card-body .pb {
  font-size: 13px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
}

.DetailModal-progress-item:not(:last-child) {
  padding-bottom: 20px;
  padding-top: 5px;
}

.DetailModal-progress-item {
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.DetailModal-progress-item h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 0px;
  margin-bottom: 15px;
  margin-top: 3px;
}

.DetailModal-progress-item p {
  font-weight: 300;
  font-size: 13px;
  line-height: 12px;
  margin-top: 10px;
}

.DetailModal-progress-item-dot {
  width: 7px;
  height: 7px;
  background-color: #0005;
  border-radius: 7px;
  position: absolute;
  left: 10px;
  top: 3px;
}

.DetailModal-progress-item:not(:last-child)::after {
  width: 1px;
  height: 35px;
  background-color: #0000;
  content: "";
  display: block;
  border: 1px dashed #00000033;
  position: absolute;
  top: 15px;
  left: 13px;
}

.DetailModal-uploads {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.DetailModal-uploads {
  width: 70px;
  height: 70px;
  position: relative;
}

.DetailModal-uploads input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.DetailModal-uploads-img {
  width: 55px;
  height: 55px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3px;
  border: 1px solid #0000;
  border-radius: 10px;
  z-index: 999;
  cursor: pointer;
}

.DetailModal-uploads-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.DetailModal-uploads-img button {
  color: #fff;
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  border-radius: 20px;
  position: absolute;
  top: 3px;
  right: 3px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.DetailModal-uploads-img button svg {
  font-size: 15px;
  margin: 2px -3px;
}

.DetailModal-card-body-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.DetailModal-card-Link {
  font-size: 12px;
  font-weight: 600;
  color: #2589f6;
  border-bottom: 1px solid #2589f6 !important;
  margin-bottom: 5px;
}

.DetailModal-card-iconbtn {
  margin: 0px 5px;
  background-color: #f000;
  font-size: 16px;
}

.DetailModal-card-bg {
  background-color: #eeecd3;
  padding: 5px 5px 5px 5px;
  border-radius: 7px;
  margin-bottom: 16px;
}

.DetailModal-card-body-note-item {
  padding: 10px 0px;
  border-bottom: 1px solid #0001;
}

.DetailModal-card-body-note-item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #0008;
}

.DetailModal-card-body-note-item-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-card-body-note-item-bottom-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.DetailModal-card-body-note-item-bottom-flex h6 {
  width: 95%;
  font-size: 16px;
  font-weight: normal;
  word-spacing: normal;
  word-break: keep-all;
}

.DetailModal-card-body-note-item-bottom-flex .DetailModal-card-body-note-item-bottom-delete {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 10px;
  padding: 4px;
  color: #ff0000;
  margin-left: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DetailModal-card-body-note-item-bottom h6 {
  white-space: nowrap;
  width: 80%;
  font-size: 13px;
  font-weight: 700;
  word-spacing: normal;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DetailModal-card-body-note-item-bottom-eye {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 10px;
  padding: 5px;
  color: #3eb2e7;
  margin-left: 5px;
}

.DetailModal-card-body-note-item-bottom-delete {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 10px;
  padding: 4px;
  color: #ff0000;
  margin-left: 5px;
}

.DetailModal-main-middle-view {
  /* height: 100%; */
  /* min-height: calc(100vh - 20px); */
}

.DetailModal-main-middle-view-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: calc(100% - 220px);
  top: 0;
  left: 220px;
  z-index: 999;
  box-shadow: 0px 1px 17px -15px #000;
}

@media only screen and (max-width: 1399.9px) {
  .DetailModal-main-middle-view-header {
    width: calc(100% - 100px);
    left: 100px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .DetailModal-main-middle-view-header {
    width: calc(100% - 65px);
    left: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header {
    width: 100%;
    left: 0;
  }
}

.DetailModal-main-middle-view-header-back {
  /* margin-right: 10px; */
  width: 75px;
  height: 75px;
  background-color: #fff;
  border-radius: 5px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  cursor: pointer;
  color: #304a50;
  padding: 0px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header-back {
    width: 40px;
    height: 55px;
    padding: 0px 8px;
  }
}

.DetailModal-main-middle-view-header-title {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 0px 0px 0px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DetailModal-main-middle-view-header-title-1 {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.DetailModal-main-middle-view-header-title-spaced {
  width: 100%;
  height: 75px;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header-title-spaced {
    height: 55px;
    padding: 0px 10px;
  }
}

.DetailModal-main-middle-view-header-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.DetailModal-main-middle-view-header-title-text {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 14px solid #387ee6;
  margin-left: 0px;
  padding-left: 25px;
}

.DetailModal-main-middle-view-header-title-text.none {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 0px solid #387ee6;
  margin-left: 0px;
  padding-left: 0px;
  width: 40%;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header-title-text.none {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #304a50;
    width: 100%;
    height: 25px;
  }
}

.DetailModal-main-middle-view-header-title-text.none ~ div h6 {
  font-size: 13px;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-header-title-text.none ~ div h6 {
    font-size: 11px;
  }
}

.DetailModal-main-middle-view-header-title-text p {
  font-size: 12px;
  padding: 5px 10px;
  margin-left: 10px;
  border: 1px solid #27333320;
  border-radius: 5px;
}

.DetailModal-main-middle-view-header-title-text-1 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-left: 14px solid #387ee6; */
  margin-left: -30px;
  padding-left: 25px;
}

.DetailModal-main-middle-view-header-title-text-small {
  font-size: 12px;
  color: #0008;
}

.DetailModal-main-middle-view-header-row button {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: rgba(39, 51, 51, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.DetailModal-main-middle-view-body {
  width: 100%;
  /* min-height: 75vh; */
  /* max-height: 75vh; */
  background-color: #fff;
  padding: 50px 5px;
  margin: 0px 0;
  border-radius: 0px 0px 8px 8px;
  /* overflow-y: auto; */
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 17px -13px #000;
  width: 850px;
  min-height: 1260px;
}

@media only screen and (max-width: 1199.9px) {
  .DetailModal-main-middle-view-body {
    width: 700px;
    min-height: 700px;
  }
}

@media screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body {
    width: 100vw;
    min-height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    padding: 7px;
    margin: 5px 0;
    overflow-x: hidden;
  }
}

.DetailModal-main-middle-view-body.no-bg {
  width: 100%;
  /* min-height: 75vh; */
  /* max-height: 75vh; */
  background-color: #fff0;
  padding: 0 10px;
  margin: 0px 0;
  border-radius: 0px 0px 8px 8px;
  /* overflow-y: auto; */
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 17px -13px #0000;
}

.DetailModal-main-middle-view-body::-webkit-scrollbar {
  width: 2px;
}
.DetailModal-main-middle-view-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.DetailModal-main-middle-view-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.DetailModal-main-middle-view-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DetailModal-main-middle-view-body-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.DetailModal-main-middle-view-options {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 0spx 15px 50px;
  background: #fff;
  border-radius: 13px;
  padding: 16px;
  z-index: 99;
}

.DetailModal-main-middle-view-options-hr {
  width: 95%;
  border: 1px dashed #000;
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 9;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-options {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    height: 100px;
    min-width: 85px;
    padding: 0px 10px;
    border-bottom: 1px solid #0001;
  }
}

.DetailModal-main-middle-view-options img {
  width: 40px;
  height: 40px;
  margin-bottom: 7px;
}
.DetailModal-main-middle-view-options .green_tick {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-options img {
    margin-right: 0px;
    margin-bottom: 7px;
    width: 35px;
    height: 35px;
  }
}

/* @media only screen and (max-width: 600px) {
  .DetailModal-main-middle-view-options img {
    width: 35px;
    height: 35px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
} */

.DetailModal-main-middle-view-options h6 {
  /* width: 100px; */
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #5e7179;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-options h6 {
    width: 100%;
    text-align: center;
  }
}

.DetailModal-main-middle-view-divider {
  width: 100%;
  height: 2px;
  background-color: #e4e9ec;
  margin: 15px 0px;
}

.DetailModal-main-middle-view-wait {
  width: 100%;
  border: 1px solid #0001;
  height: 660px;
  /* height: 75vh; */
  border-radius: 10px 10px 10px 10px;
  padding-top: 10px;
  margin-top: 30px;
  background-color: #fff;
  filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.25));
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-wait {
    border-radius: 3px;
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 61vh;
    padding: 2px 0px 0px;
  }
}

.DetailModal-main-middle-view-chat {
  width: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  /* background-color: rgba(239, 241, 246, 1); */
  margin: 0px;
  border-radius: 0px 0px 0px 0px;
  height: 94%;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat {
    width: 100%;
  }
}

.DetailModal-main-middle-view-chat-left {
  background: #f5f5f5;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 0px;
  height: 100%;
  padding: 20px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 175px;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-left {
    height: 100%;
    width: 100%;
    padding: 15px 30px;
  }
}

.DetailModal-main-middle-view-chat-left h6 {
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  color: #304a5090;
  margin-bottom: 15px;
}

.DetailModal-main-middle-view-chat-left-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 10px;
  background: #797b7c00;
  border-radius: 12px;
  border-bottom: 1px solid #0000;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-left-menu {
    background-color: #fff;
    margin-bottom: 10px;
  }
}

.DetailModal-main-middle-view-chat-left-menu h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.01em;
  background: #304a50;
  border-radius: 43px;
  color: #ffffff;
  padding: 4px 7px;
}

.DetailModal-main-middle-view-chat-left-menu.active h2 {
  color: #2589f6;
  background-color: #fff;
}

.DetailModal-main-middle-view-chat-left-menu.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 10px;
  background: #2589f6;
  border-radius: 12px;
  cursor: pointer;
}

.DetailModal-main-middle-view-chat-left-menu img {
  width: 25px;
  margin-right: 10px;
}
.DetailModal-main-middle-view-chat-left-menu h5 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: rgba(87, 107, 115, 1);
}

.DetailModal-main-middle-view-chat-left-menu.active h5 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}

.DetailModal-main-middle-view-chat-right {
  /* background: #c8ccd8; */
  border-radius: 11px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-right {
    height: 58vh;
    width: 100%;
  }
}

.DetailModal-main-middle-view-chat-right-body {
  padding: 20px 20px 80px 20px;
  background-color: #0000;
  width: 100%;
  /* flex: 3;
  height: 100px; */
  height: calc(90% - 25px) !important;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-right-body {
    padding: 7px 0px 80px 7px;
  }
}

.DetailModal-main-middle-view-chat-right-body::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.DetailModal-main-middle-view-chat-right-body::-webkit-scrollbar-track {
  background: #fff;
}
.DetailModal-main-middle-view-chat-right-body::-webkit-scrollbar-thumb {
  background: #304a5050;
  border-radius: 10px;
}
.DetailModal-main-middle-view-chat-right-body::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.DetailModal-main-middle-view-chat-right-note {
  /* padding: 20px; */
  background-color: #f000;
  width: 90%;
  flex: 1;
  border-radius: 10px !important;
  border: 5px solid #2589f6;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-right-note {
    width: 100%;
  }
}

.DetailModal-main-middle-view-chat-right-send {
  background-color: #efefef;
  width: 100%;
  padding: 10px 10px 10px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -8px;
  right: 0;
  width: calc(100% - 175px);
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-chat-right-send {
    padding: 0px 10px;
    width: 100%;
    height: 70px;
  }
}

.DetailModal-main-middle-view-chat-right-send-input-wrapper {
  background: #fff;
  height: 50px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  overflow: hidden;
}

.DetailModal-main-middle-view-chat-right-send-input-wrapper input[type="text"] {
  border: 0px !important;
  /* background-color: #f00 !important; */
  width: 85%;
  height: 40px;
  background-color: #fff !important;
  outline: 0px;
  padding: 0 20px;
}

.DetailModal-main-middle-view-chat-right-send-input-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}

.DetailModal-main-middle-view-chat-right-send-btn {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2589f6;
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
}

/* 
.DetailModal-main-middle-view-chat-header {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.DetailModal-main-middle-view-chat-body {
  width: 100%;
  padding: 15px;
  background-color: rgba(12, 42, 49, 0.04);
  margin-top: 15px;
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.DetailModal-main-middle-view-header-title-img {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.DetailModal-main-middle-view-header-row .btn-blue {
  background-color: #2589f6;
  width: auto;
  border-radius: 30px;
  font-size: 14px;
  padding: 3px 15px;
  letter-spacing: 0.05em;
  color: #fff;
}

.DetailModal-main-middle-view-body-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.DetailModal-main-middle-view-body-row-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.DetailModal-main-middle-view-body-row-spaced {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-row-spaced.mobile {
    width: 103%;
    overflow-x: auto;
    padding: 10px 0;
  }
}

.DetailModal-main-middle-view-body-row.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .DetailModal-main-middle-view-body-row-center {
    flex-direction: column;
  }
}

.DetailModal-main-middle-view-body-row-col {
  flex: 1;
  padding: 0px 10px;
  position: relative;
}

.DetailModal-main-middle-view-body-row-col-row-end {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-row-col-row-end {
    padding: 0px;
  }
  .DetailModal-main-middle-view-body-row-col-row-end.mbCenter {
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-row-col {
    padding: 5px 2px;
    width: 100%;
  }
}

.DetailModal-main-middle-view-body-row-col-2 {
  width: 50%;
  padding: 0px 10px;
  position: relative;
}

@media only screen and (max-width: 1199.9px) {
  .DetailModal-main-middle-view-body-row-col-2 {
    width: 38%;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-row-col-2 {
    width: 100%;
    padding: 0px 2px;
  }
}

.DetailModal-main-middle-view-body-details {
  margin-top: 2%;
}

.DetailModal-main-middle-view-body-details-small-text {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #27333370;
}

.DetailModal-main-middle-view-body-details-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #304a50;
}

.DetailModal-main-middle-view-body-details-text-btn {
  margin-left: 10px;
  margin-bottom: -5px;
  cursor: pointer;
}

.DetailModal-main-middle-view-body-details-text-white {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-details-text {
    font-size: 12px;
    font-weight: bold;
  }
}

.DetailModal-main-middle-view-body-header-img {
  /* width: 100%; */
  height: 90px;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-header-img {
    height: 65px;
    margin: 15px 0px;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #0001;
  margin: 15px 0;
}

.DetailModal-main-middle-view-body-input {
  width: 100%;
  margin: 10px 0px;
  border-radius: 5px;
  /* background-color: rgba(12, 42, 49, 0.04) !important; */
  height: 40px;
  font-size: 16px;
  padding: 0px 10px;
  background: #e2e5e6 !important ;
  border: none !important;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-input {
    margin: 0px 0px;
  }
}

.DetailModal-main-middle-view-body-input-area {
  width: 100%;
  margin: 10px 0px;
  border-radius: 5px;
  height: 92px;
  /* background-color: rgba(12, 42, 49, 0.04) !important; */
  font-size: 16px;
  padding: 0px 10px;
  background: #e2e5e6 !important ;
  border: none !important;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-input-area {
    margin: 0px;
  }
}

.DetailModal-main-middle-view-body-input:focus {
  border: none;
}

.DetailModal-main-middle-view-body-row .btn-delete {
  background-color: #fff;
  color: #f00;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DetailModal-main-middle-view-body-row .btn-light {
  background-color: #fff;
  color: rgba(12, 42, 49, 0.85);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-light {
  background-color: #fff;
}

.DetailModal-main-middle-view-body-btn-plus {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px dashed #0005;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.DetailModal-main-middle-view-body-subtotal {
  padding: 10px;
  background-color: rgba(226, 229, 230, 1);
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-subtotal {
    width: 100vw;
    margin-left: -5.5%;
    border-radius: 0px;
    margin-bottom: -40px;
    border-width: 0px !important;
    padding: 5px 20px;
  }
}

.DetailModal-main-middle-view-body-chat {
  width: 100%;
  min-height: 65vh;
  max-height: 65vh;
  background-color: #0000;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .DetailModal-main-middle-view-body-chat {
    min-height: 51vh;
    max-height: 51vh;
  }
}

.DetailModal-main-middle-view-body-chat::-webkit-scrollbar {
  width: 2px;
}
.DetailModal-main-middle-view-body-chat::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.DetailModal-main-middle-view-body-chat::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.DetailModal-main-middle-view-body-chat::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.DetailModal-main-middle-view-body-chat-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
}

.DetailModal-main-middle-view-body-chat-left-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.DetailModal-main-middle-view-body-chat-left-wrapper {
  max-width: 70%;
}

.DetailModal-main-middle-view-body-chat-left-wrapper-msg {
  padding: 7px 15px;
  background-color: rgba(39, 51, 51, 1);
  color: #ffff;
  border-radius: 5px;
}

.DetailModal-main-middle-view-body-chat-left-wrapper-spaced {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
}

.DetailModal-main-middle-view-body-chat-left-wrapper-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-main-middle-view-body-chat-left-wrapper-row-time {
  font-size: 10px;
}

.DetailModal-main-middle-view-body-chat-right {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row-reverse;
}

.DetailModal-main-middle-view-body-chat-right-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}

.DetailModal-main-middle-view-body-chat-right-wrapper {
  max-width: 70%;
}

.DetailModal-main-middle-view-body-chat-right-wrapper-msg {
  padding: 7px 15px;
  background-color: #2589f6;
  color: #ffff;
  border-radius: 5px;
}

.DetailModal-main-middle-view-body-chat-right-wrapper-spaced {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 6px 0px;
}

.DetailModal-main-middle-view-body-chat-right-wrapper-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-main-middle-view-body-chat-right-wrapper-row-time {
  font-size: 10px;
}

.chat-send {
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #e4e9ec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-send-input {
  width: 90%;
  padding: 0 10px;
  border: none;
  border-radius: 30px;
  height: 40px;
  margin-right: 15px;
}

.chat-send-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2589f6;
  box-shadow: 0px 2px 1px -2px #0004;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.chat-summary-header {
  padding: 10px;
  background-color: #bb53c5;
  border-radius: 10px;
  color: #fff;
}

.DetailModal-bottom-section {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a3c43;
  box-shadow: 0px -3px 7px rgb(0 0 0 / 25%);
  border-radius: 0;
  font-size: 12px;
  margin: 5px 0px 0px;
  padding: 0px 0px;
  position: absolute;
  bottom: 0;
  z-index: 9999;
}

.DetailModal-btn-white {
  flex: 1;
  border-radius: 0;
  margin: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 8px;
  background-color: #0000;
  color: #fff;
  font-weight: 300;
  padding: 10px 0px;
}

.DetailModal-btn-white svg {
  font-size: 16px;
  margin-bottom: 7px;
}

.DetailModal-btn-white.active {
  background-color: #ffffff15;
  color: #fff;
}

.DetailModal-mainView {
  flex: 1 1;
  background: #e4e9ec;
  border-radius: 5px;
  padding: 0;
  width: 100%;
  /* overflow-y: hidden; */
}

@media only screen and (max-width: 768px) {
  .DetailModal-mainView {
    width: 100vw;
    /* background: #f00; */
    height: calc(100vh - 111px);
    border-radius: 0px;
    margin-top: -4px;
    padding: 0px;
    position: absolute;
    left: 0;
    top: 60px;
    overflow: hidden;
  }
}

.setComplete_modal {
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #0005;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setComplete_modal-body {
  padding: 15px;
  position: relative;
  background-color: #fff;
  min-width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setComplete_modal-body img {
  width: 60px;
  height: 60px;
  margin: 10px auto 15px;
}

.setComplete_modal-body h5 {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 0px solid #0001;
  font-size: 20px;
  font-weight: 600;
  color: #304a50;
}

.setComplete_modal-body h6 {
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 0px solid #0001;
  font-size: 20px;
  font-weight: 600;
  color: #304a50;
}

.setComplete_modal-body input {
  margin-left: 10px;
  background-color: #fff;
}

.setComplete_modal-body-close {
  color: #0c2a31;
  position: absolute;
  right: 10px;
  top: 10px;
}

/*  */
.ReturnToClient_modal {
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #0005;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReturnToClient_modal-body {
  padding: 15px;
  position: relative;
  background-color: #fff;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ReturnToClient_modal-body img {
  width: 60px;
  height: 60px;
  margin: 10px auto 10px;
}

.ReturnToClient_modal-body h6 {
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #0001;
  font-size: 20px;
}

.ReturnToClient_modal-body input {
  margin-left: 10px;
  background-color: #fff;
}

.ReturnToClient_modal-body p {
  padding: 10px;
  text-align: center;
  font-weight: 600;
  color: #27333370;
  font-size: 14px;
  margin-bottom: 25px;
}

.ReturnToClient_modal-body-close {
  color: #0c2a31;
  position: absolute;
  right: 10px;
  top: 10px;
}

.DetailModal-main-middle .rs-dropdown-toggle-custom-title {
  width: 100%;
}

.bttn-chevrom {
  padding: 5px 10px;
  border-radius: 50px;
  background-color: #2589f6;
  width: 120px;
  height: 45px;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .bttn-chevrom {
    width: 95px;
    height: 35px;
    font-size: 16px;
    padding: 0px 5px;
  }
}

.bttn-chevrom div {
  border-right: 1px solid #fff7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.rs-btn-default.rs-btn-violet {
  background-color: rgba(48, 74, 80, 1) !important;
}

.rs-btn-ghost.rs-btn-violet {
  color: rgba(48, 74, 80, 1);
  border: 1px solid rgba(48, 74, 80, 1);
  background-color: transparent;
}

.due-amount {
  font-size: 20px !important;
  margin: 15px 5px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .due-amount {
    font-size: 20px !important;
    margin: 22px 0px 50px;
    font-weight: bold;
  }
}

.table-header {
  background: #697c80;
  /* box-shadow: 2px 6px 18px rgba(0, 0, 0, 0.14); */
  border-radius: 5px;
  padding: 10px 1px;
}

@media only screen and (max-width: 768px) {
  .table-header {
    background: #697c80;
    /* box-shadow: 2px 6px 18px rgb(0 0 0 / 14%); */
    border-radius: 5px;
    padding: 0px 5px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
}

.table-header-chat {
  background: #2589f6;
  box-shadow: 2px 6px 18px rgba(0, 0, 0, 0.14);
  border-radius: 0px;
  padding: 10px 2px;
}

p {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;

  color: #4f656a;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 36, 52, 0.66);
  backdrop-filter: blur(0px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.gallery_modal.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #000 !important;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

/*  */
.DetailModal-card-bg.card_modal {
  background-color: rgba(238, 236, 211, 1);
  padding: 40px 40px 40px 40px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 1024px;
  height: 500px;
}

.DetailModal-card-header.card_modal {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.security_modal .DetailModal-card-header.card_modal {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DetailModal-card-header.card_modal h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #304a50;
  margin-bottom: 15px;
}

.card_modal .DetailModal-card-body-note-item-bottom h6 {
  white-space: pre-wrap;
  width: 100%;
  word-spacing: normal;
  word-break: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* or 19px */
  letter-spacing: 0.03em;
  color: #000000;
}

.card_modal .DetailModal-card-body-note-item-top-text {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #0c2a31;
}

.card_modal .DetailModal-card-body {
  padding: 5px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  max-height: 85%;
  overflow-y: auto;
}

.DetailModal-card-body-note-item-bottom_textArea {
  width: 100%;
  height: 150px;
  background-color: #0000;
  border: none;
  outline: none;
  color: #0c2a31;
}

.card_modal button.btn.btn-secondary {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 100px;
  background-color: rgba(160, 173, 179, 1);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}

.card_modal button.btn.btn-settings {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  background-color: #4f656a;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_modal button.btn.btn-primary {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 100px;
  background-color: #2589f6;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
}

.setComplete_modal-body .btn.btn-primary {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 100px;
  background-color: #2589f6;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-top: 25px;
  color: #ffffff;
}

.ReturnToClient_modal .btn.btn-primary {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 100px;
  background-color: #2589f6;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;

  color: #ffffff;
}

.card_modal .boc {
  background: #ffffff;
  box-shadow: 2px 6px 18px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;
}

.card_modal .boc ~ h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  color: #000000;
}

.card_modal.upload_modal {
  width: 600px !important;
  height: auto !important;
  background-color: #fff !important;
}

.card_modal_input_wrapper {
  width: 100%;
  position: relative;
  border: 1px solid #e4e7f0;
  border-radius: 12px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
}

.card_modal_input_wrapper label {
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  /* color: #A0ADB3; */
  background-color: #fff;
}

/* .card_modal_input::placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #A0ADB3;
}

.card_modal_input option {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #A0ADB3;
} */

.card_modal_input {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #fff;
}

.card_modal_input_2 {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #ededed !important;
}

.card_modal_input.textArea {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 12px;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #fff !important;
  height: 200px;
}

.card_modal_input_check {
  margin-right: 5px;
  color: #ffd369 !important;
  background-color: #fff !important;
  --background: #fff !important;
  --color: #fff !important;
  display: none;
}

.card_modal_input_check_label {
  width: 20px;
  height: 20px;
  border: 1px solid #a0adb3;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.card_modal_input_check:checked ~ .card_modal_input_check_label {
  border: 1px solid #000;
  background-color: #000;
}

.card_modal_input_label {
  position: absolute;
  top: 15px;
  left: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #a0adb3;
  background-color: #fff;
  padding: 1px 3px;
}

.card_modal_input:focus ~ .card_modal_input_label,
.card_modal_input:valid ~ .card_modal_input_label {
  top: -10px;
}

.security_modal {
  width: 600px !important;
  height: auto !important;
  background-color: #fff !important;
}

.DetailModal-card-status {
  padding: 1.5px 5px;
  width: 75px;
  background: #f88b26;
  border-radius: 45px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  margin: 0px 5px;
}

.change_status_modal {
  width: 1000px !important;
  height: auto !important;
  background-color: #fff !important;
  z-index: 99999;
}

.change_status_modal .DetailModal-card-header.card_modal {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DetailModal-table-body {
  background: #ffffff;
  box-shadow: 2px 6px 18px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 10px;
}

.PP {
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #000000;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.gallery_modal {
  width: 1024px !important;
  height: 600px !important;
  position: relative;
  background-color: #fff0 !important;
  border: 15px;
  padding: 0px !important;
}

.gallery_modal_img_lg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  display: none;
  object-fit: contain;
  background-color: #fff2;
}

.active.gallery_modal_img_lg {
  display: block;
}

.gallery_modal_chevron_left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 20px;
  background-color: #fff0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gallery_modal_chevron_right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 20px;
  background-color: #fff0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gallery_modal_chevron_icon {
  font-size: 70px;
  color: #fff;
}

.gallery_modal_img_ribbon {
  width: 100%;
  padding: 5px;
  height: 70px;
  background-color: #fff5;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery_modal_img_thumb {
  width: 70px;
  height: 60px;
  border-radius: 7px;
  margin: 5px;
  cursor: pointer;
  object-fit: contain;
  background: #0002;
}

.gallery_modal_img_thumb.active {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 24px rgba(255, 255, 255, 0.25));
  width: 100px;
  height: 100px;
}

.blue-boc {
  padding: 4px;
  margin-left: 10px;
  background-color: #3eb2e7;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .blue-boc {
    height: 18px;
    width: 18px;
    padding: 0px;
    margin-left: 6px;
    border-radius: 3px;
  }
}

.DetailModal-main-middle-view-tab-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #838383;
  padding: 5px 0px;
  margin: 0 10px;
  border-bottom: 5px solid #0000;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-tab-text {
    padding: 3px 0px;
    font-size: 15px;
    border-bottom: 3px solid #0000;
  }
}

.DetailModal-main-middle-view-tab-text.active {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: rgba(12, 42, 49, 0.85);
  border-bottom: 5px solid #3eb2e7;
}
@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-tab-text.active {
    padding: 3px 0px;
    font-size: 15px;
    border-bottom: 3px solid #3eb2e7;
  }
}

.DetailModal-main-middle-view-body-details-text-chat {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  padding: 10px 5px;
  color: #273333;
}

.DetailModal-main-middle-view-body-details-text-chat-bold {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;

  color: #273333;
}

.DetailModal-main-middle-view-body-details-text-chat-bold span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
  color: #273333;
}

.search {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 30px;
  margin: 7px;
}

.search input {
  border: 0px;
}

.DetailModal-main-middle-view-chat-right-body-left {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 7px;
  border-radius: 10px;
}

.DetailModal-main-middle-view-chat-right-body-right {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 7px;
}

.DetailModal-main-middle-view-chat-right-body-left-dp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.DetailModal-main-middle-view-chat-right-body-right .DetailModal-main-middle-view-chat-right-body-left-dp-circle {
  background: #00adef;
  /* margin-left: 10px; */
  color: #fff;
}

.DetailModal-main-middle-view-chat-right-body-left-dp-circle {
  width: 45.92px;
  height: 45.73px;
  background: #fec636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: right;
  color: #273333;
  position: relative;
}

.DetailModal-main-middle-view-chat-right-body-left-dp-circle img {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
}

.DetailModal-main-middle-view-chat-right-body-left-dp-circle h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: right;
  color: #273333;
}

.DetailModal-main-middle-view-chat-right-body-left-dp h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: #000000;
}

.DetailModal-main-middle-view-chat-right-body-left-dp h6 {
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  text-align: right;

  color: #273333;
}

.DetailModal-main-middle-view-chat-right-body-left-msg {
  background: #ededed;
  border-radius: 10px;
  max-width: 70%;
  padding: 8px;
  max-height: auto;
  margin-left: 15px;
  position: relative;
  color: #000;
}

.DetailModal-main-middle-view-chat-right-body-left-msg::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ededed;
  position: absolute;
  transform: rotate(45deg);
  left: -10px;
  top: 18px;
}

.DetailModal-main-middle-view-chat-right-body-right .DetailModal-main-middle-view-chat-right-body-left-msg {
  background: #00adef;
  border-radius: 10px;
  max-width: 70%;
  padding: 8px;
  max-height: auto;
  margin-left: 25px;
  position: relative;
}

.DetailModal-main-middle-view-chat-right-body-right .DetailModal-main-middle-view-chat-right-body-left-msg::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #00adef;
  position: absolute;
  transform: rotate(45deg);
  right: -10px;
  left: auto;
  top: 18px;
}

.DetailModal-main-middle-view-chat-right-body-right .DetailModal-main-middle-view-chat-right-body-left-msg h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #fff;
}

.DetailModal-main-middle-view-chat-right-body-left-msg h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  color: #304a50;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .DetailModal-main-middle-view-chat-right-body-left-dp-circle {
  width: 35.92px;
  height: 35.73px;
  background: #fec636;
  border-radius: 50%;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .play {
  width: 25px;
  height: 25px;
  border: 1px solid #0002;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .progress {
  position: relative;
  width: 98%;
  margin-top: 22px;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .progress .line-muted {
  width: 100%;
  border: 1px solid #0c2a3120;
  position: absolute;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .progress .line {
  width: 30%;
  border: 1px solid #0c2a31;
  position: relative;
}

.callList.DetailModal-main-middle-view-chat-right-body-left .progress .line .cirlce {
  width: 10px;
  height: 10px;
  border: 2px solid #0c2a31;
  position: absolute;
  top: -5px;
  right: -1px;
  border-radius: 50%;
  background: #fff;
}

.DetailModal-main-middle-view-chat-right-body-left.callList p {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #273333;
  width: 100%;
  /* background: #0005; */
  text-align: left;
  margin-bottom: 4px;
}

.DetailModal-main-middle-view-chat-right-body-left.callList h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #304a50;
}

.DetailModal-main-middle-view-chat-right-body-left.callList h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #304a50;
  width: 100%;
  /* background: #0005; */
  text-align: left;
}

.DetailModal-main-middle-view-chat-right-body-right .DetailModal-main-middle-view-chat-right-body-left-msg-time {
  color: #fff;
}

.DetailModal-main-middle-view-chat-status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.DetailModal-main-middle-view-chat-status-inner {
  padding: 5px;
  border-radius: 10px;
  background-color: #2589f6;
  width: 170px;
}

.DetailModal-main-middle-view-chat-status-img {
  width: 50px;
  height: 35px;
  object-fit: contain;
  padding-right: 7px;
  margin-right: 7px;
  border-right: 1px solid #fff;
}

.DetailModal-main-middle-view-chat-status-line {
  flex: 1;
  height: 2px;
  background-color: #b5bcc8;
  margin: 0px 10px;
}

.DetailModal-main-middle-view-chat-status-inner h5 {
  font-size: 16px;
  color: #fff;
}
.DetailModal-main-middle-view-chat-status-inner h6 {
  font-size: 12px;
  color: #fff;
  padding: 0px 5px;
  font-weight: 400;
}

.DetailModal-main-middle-view-chat-status-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DetailModal-main-middle-view-chat-status-flex-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DetailModal-main-middle-view-chat-status-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.DetailModal-main-middle-view-chat-status-img-icon {
  width: 15px;
  height: 15px;
}

.dropDownRowSpaced {
  cursor: pointer;
}

.dropDownRowSpaced h6 {
  font-size: 12px;
  margin-left: 7px;
  font-weight: 400;
  color: #304a50;
}

.DetailModal-main-middle-view-body-chat-modal {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 999;
  width: 100%;
}

.DetailModal-main-middle-view-body-chat-modal .DetailModal-main-middle-view-chat-left,
.DetailModal-main-middle-view-body-chat-modal .DetailModal-main-middle-view-chat-right {
  height: 60vh;
}

.DetailModal-main-middle-view-body-chat-modal-sending {
  padding: 15px 50px;
  text-align: center;
  width: 350px;
  background-color: #ededed;
  border-radius: 5px;
  margin-bottom: -5px;
  font-weight: 600;
  font-size: 18px;
  color: #304a50;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

.DetailModal-main-middle-view-body-chat-modal-close {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e200000a;
  position: absolute;
  top: 60px;
  right: 12px;
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
}

.DetailModal-main-middle-view-body-serviceReciptContainer {
  margin-left: 5%;
  margin-right: 30px;
  width: 90%;
}

@media only screen and (max-width: 768px) {
  .DetailModal-main-middle-view-body-serviceReciptContainer {
    margin-left: 2.5%;
    margin-right: 30px;
    width: 95%;
  }
  .DetailModal-main-middle-view-body-serviceReciptContainer.mobile {
    width: 89.5%;
    position: relative;
  }
}

.btn-onHoverInput {
  position: absolute;
}

.btn-onHoverInput.starting {
  left: 15px;
}

@media only screen and (max-width: 768px) {
  .btn-onHoverInput.starting {
    left: auto;
    right: -25px;
    top: 7px;
  }
}

.btn-onHoverInput.ending {
  right: 15px;
}

@media only screen and (max-width: 768px) {
  .btn-onHoverInput.ending {
    right: -25px;
    top: 35px;
  }
}

.DetailModal-main-middle-view-chat_activity {
  width: 100%;
  padding-top: 15px;
}

.DetailModal-main-middle-view-chat_activity_graybg {
  width: calc(100% - 20px);
  border-radius: 10px;
  background-color: #c4c4c430;
  padding: 10px;
  margin: 10px;
}

.search_wrapper {
  flex: 1;
  margin-right: 5px;
  padding: 5px;
  background-color: #ededed;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 100px;
}
.search_wrapper_input {
  width: 90%;
  background-color: #0000 !important;
  border: 0;
  color: #000;
}
.bt-search {
  width: 25px;
  height: 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.bt-plus {
  width: 35px;
  height: 35px;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.DetailModal-main-middle-view-chat-right-send-tabs {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.DetailModal-main-middle-view-chat-right-send-tab {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px 10px 0px;
  border-bottom: 3px solid #2589f600;
}
.DetailModal-main-middle-view-chat-right-send-tab.active {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 3px solid #2589f6;
}
.DetailModal-main-middle-view-chat-right-send-tab img {
  width: 25px;
  height: 15px;
  object-fit: contain;
  margin-right: 5px;
}

.DetailModal-main-middle-view-chat-right-send-tab p {
  color: #838383;
  font-weight: 700;
}

.DetailModal-main-middle-view-chat-right-send-tab.active p {
  color: #0c2a31;
  font-weight: 700;
}

.ChatBoxSearchCall_dp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ChatBoxSearchCall_dp img {
  width: 30px;
  height: 30px;
  margin-right: 7px;
}
.ChatBoxSearchCall_dp h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #304a50;
}
.ChatBoxSearchCall_dp p {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: rgba(48, 74, 80, 0.65);
}
.ChatBoxSearchCall_numbers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 68%;
  padding: 0px 10px;
  overflow-x: auto;
  margin-right: 7px;
}
@media only screen and (max-width: 768px) {
  .ChatBoxSearchCall_numbers {
    width: 60%;
  }
}

.ChatBoxSearchCall_numbers::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}
.ChatBoxSearchCall_numbers::-webkit-scrollbar-track {
  background: #0000;
}
.ChatBoxSearchCall_numbers::-webkit-scrollbar-thumb {
  background: #3331;
  border-radius: 10px;
}
.ChatBoxSearchCall_numbers::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ChatBoxSearchCall_number {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  background: #ededed;
  border-radius: 51px;
  letter-spacing: 0.01em;
  color: #304a50;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
}

.ChatBoxSearchCall_number.active {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  background: #fff;
  border-radius: 51px;
  letter-spacing: 0.01em;
  color: #2589f6;
  border: 1px solid #2589f6;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
}
.keypad_tab {
  /* width: 525px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
}

.keypad_tab img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.keypad_tab .CallName {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #304a50;
}
.CallNumber {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #304a50;
}
.CallTime {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #304a50;
}
.keypad_tab .callBtnImages {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px;
  cursor: pointer;
}
.number_pad {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 200px;
  flex-wrap: wrap;
}
.number_pad_btn {
  width: 52px;
  height: 52px;
  background: #ededed;
  border-radius: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 7px;
  margin: 7px;
  cursor: pointer;
}
.number_pad_call_btn {
  width: 70px;
  height: 70px;
  background: #00ba1f;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 30px;
  margin-top: 20px;
  cursor: pointer;
}
.number_pad_btn p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #0c2a31;
}
.number_pad_btn h5 {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #0c2a31;
}
.number_pad_input {
  width: 200px;
  height: 45px;
  background: #ededed !important;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #304a50;
  text-align: center;
  margin-bottom: 20px;
  border: none !important;
  outline: none !important;
}

.muted_text {
  font-weight: 500;
  color: #304a5065;
}
.blue_text {
  font-weight: 500;
  color: #2589f6;
}
.DetailModal-card-bg.card_modal .security_modal_pattern {
  width: 32px;
  height: 32px;
  background: #cbcbcb;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 37px;
  text-align: center;
  padding-top: 6px;
  margin: 10px 10px;
  cursor: pointer;
}
.DetailModal-card-bg.card_modal .security_modal_pattern.active {
  background: #2589f6;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 37px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}
.security_modal_pattern_check {
  width: 38px;
  height: 38px;
  background: #00ba1f;
  border-radius: 33px;
  font-size: x-large;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
