@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

// Utility

.pos-relative {
  position: relative !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer {
  cursor: pointer !important;
}

.desk-only {
  display: block;
}


@media only screen and (max-width: 769px) {
  .desk-only {
    display: none;
  }
}

.mob-only {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mob-only {
    display: block;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5px;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between !important;
}

.row-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px;
}

@media only screen and (max-width: 600px) {
  .mob-row-column {
    flex-direction: column !important;
  }
}

.w-100 {
  width: 100% !important;
}

.w-280 {
  width: 280px !important;
}

.f-1 {
  flex: 1;
}

.mt-25 {
  margin-top: 25px;
}

.mt-07 {
  margin-top: 7px;
}

.mt-1 {
  margin-top: 7px;
}

.mb-1 {
  margin-bottom: 10px;
}

.flex-row {
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
}

.flex-row_col {
  flex: 1;
  padding: 5px;
}

.text-center {
  text-align: center;
}

.AllTicketsPage-view-item-right .rs-dropdown-menu {
  left: auto !important;
  right: 0px;
}

.AllTicketItems-dropdown {
  width: 200px;
  padding: 0 10px;
}

.d-customer-container {
  padding: 1rem 0.25rem;
}

.d-customer-container:hover {
  color: #2589f6 !important;
  background: #e6f2ff;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #304a50;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.d-flex-1 {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.dis-none {
  display: none;
}

/* Buttons */

.bt-secondary,
.bt-primary {
  flex: 1;
  margin: 5px;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #a0adb3;
  min-width: 110px;
  height: 45px;
}

.bt-primary {
  background-color: #2589f6;
  color: #fff;
}

// buttons transparent

.btn-trans {
  background-color: #ffffff10;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  margin: 0px 10px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .btn-trans {
    background-color: #ffffff10;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    position: relative;
    margin: 0px 6px;
    border-radius: 50%;
  }
}

// Page Header
.PageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
}

@media only screen and (max-width: 768px) {
  .PageHeader {
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
    border-bottom: 0px solid #fff2;
  }
}

.PageHeader-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  color: #fff !important;
}

.PageHeader-left h2 {
  font-size: 25px;
  margin: 0px 10px;
}

.PageHeader-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// Settings Page Header

.SettingsPageHeader {
  width: calc(100vw - 222px);
  height: 75px;
  position: fixed;
  background-color: #fff;
  margin: -10px;
  margin-left: -66px;
  z-index: 99;
  padding: 15px;
  box-shadow: 0px 0px 5px #0003;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1399.99px) {
  .SettingsPageHeader {
    width: calc(100vw - 100px);
    margin-left: -9px;
  }
}

@media only screen and (max-width: 1199.99px) {
  .SettingsPageHeader {
    width: calc(100vw - 68px);
    margin-left: -9px;
  }
}

.SettingsPageHeader .PageHeader-left {
  display: flex;
  flex: 1;
}

.SettingsPageHeader .PageHeader-left svg,
.SettingsPageHeader .PageHeader-left h2 {
  color: #304a50;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.SettingsPageHeader .PageHeader-left svg {
  font-size: 25px;
  line-height: 35px;
}

// React Suits overwrites
.rs-checkbox-checker label {
  color: #000 !important;
}

.wrapper .listItem {
  color: #000;
  background-color: #00000008;
}

.dropDown_wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #0000;
  position: fixed;
  top: 0;
  left: 0;
}

.dropDown_container {
  background-color: #fff;
  position: absolute;
  z-index: 9999;
  border-radius: 5px;
  top: 40px;
  right: 15px;
  box-shadow: 0px 5px 30px -5px #0002;
}

.quick_task_datetime_picker a {
  padding-left: 0px !important;
}

.quick_task_datetime_picker a span {
  color: #4f656a !important;
}

.note-textinput {
  border: none !important;
  outline: none !important;
  background: #0000 !important;
  width: 100%;
}

.JobsOverview_body_top_filters {
  display: flex;
  flex-direction: column;
}

.dropDownRowSpaced {
  width: 130px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0001;
  color: #273333;
  padding: 5px 8px;
}

.dropDownTheme {
  width: 340px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .dropDownTheme {
    width: 250px;
  }
}

.dropDownTheme::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: 20px;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .dropDownTheme::after {
    right: 10px;
  }
}

.dropDownTheme-img.active {
  border: 2px solid #3eb2e7;
  border-radius: 7px;
}

.dropDownThemeTitle {
  text-align: center;
  font-size: 14px;
  color: #273333;
  padding: 12px;
}

.dropDownTheme-row {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
}

.dropDownTheme-img-wrapper {
  width: 31%;
  margin: 1%;
  cursor: pointer;
}

.dropDownTheme-img {
  /* width: 31%;
  margin: 1%; */
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 7px;
}

.dropDownTheme-img-wrapper svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  display: none;
  background: #ccc;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  padding: 2px;
}

.dropDownTheme-img.active ~ svg {
  display: inline-block;
}

.HeaderTop-right .rs-dropdown-menu {
  left: auto !important;
  right: 0px;
}
.actionDropPageHeader img {
  width: 14px;
  margin-right: 0.5rem;
}

.actionDropPageHeader .rs-dropdown-menu {
  padding: 7px 0px;
}

.actionDropPageHeader .rs-dropdown-menu::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: 20px;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .actionDropPageHeader .rs-dropdown-menu::after {
    right: 2px;
  }
}

.export.actionDropPageHeader .rs-dropdown-menu::after {
  right: 8px;
}

.actionDropPageHeader.calender.rs-dropdown > .rs-dropdown-menu {
  width: 300px !important;
}

@media only screen and (max-width: 768px) {
  .actionDropPageHeader.calender.rs-dropdown > .rs-dropdown-menu {
    width: 280px !important;
    height: 335px !important;
    overflow-y: auto;
  }
}

.sort.actionDropPageHeader.rs-dropdown > .rs-dropdown-menu {
  width: 250px !important;
  right: -8px;
}

@media only screen and (max-width: 768px) {
  .sort.actionDropPageHeader.rs-dropdown > .rs-dropdown-menu {
    width: 250px !important;
    max-height: 335px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 768px) {
  .actionDropPage
    .rs-dropdown-open.rs-dropdown-placement-top-end
    > .rs-dropdown-menu {
    right: 220%;
  }
}

.dropDownProfileWrapper .rs-dropdown-menu {
  left: auto !important;
  right: 0px !important;
  top: 130% !important;
  /* position: relative; */
}

.sort_drop h5 {
  text-align: center;
  border-bottom: 1px solid #0001;
}

.dropDownProfileWrapper .rs-dropdown-menu::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  top: -5px;
  right: 20px;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .dropDownProfileWrapper .rs-dropdown-menu::after {
    right: 8px;
  }
}

.dropDownProfile {
  padding: 7px 3px;
}

.dropDownProfile-top {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #0001;
}

.dropDownProfile-top-img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.dropDownProfile-top-avatar-text {
  height: 50px;
  width: 50px;
  margin-right: 15px;
  // padding: 8px;
  background: #2589f6;
  border-radius: 50%;
  text-transform: uppercase;
}

.dropDownProfile-top-avatar-text p {
  text-align: center;
  color: #fff;
  vertical-align: middle;
  line-height: 50px;
}

.dropDownProfile-right h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: #4e546c;
  margin-bottom: -5px;
}

.dropDownProfile-right h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height, or 187% */
  margin-bottom: 5px;
  color: rgba(79, 79, 79, 0.7);
}

.dropDownProfile-right h6 {
  padding: 0px 10px;
  border-radius: 30px;
  background-color: #3eb2e7;
  width: fit-content;
  font-size: 9px;
  font-weight: normal;
  letter-spacing: 0.1em;
  color: #fff;
  height: 15px;
  line-height: 15px;
}

.dropDownProfile-Link {
  width: 100%;
  padding: 8px 8px;
  color: #304A50 ;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  text-decoration: none !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropDownProfile-Link:hover {
  background-color:#e5f2ff !important;
 
}

.dropDownProfile-Link svg {
  color: #273333;
  margin-right: 10px;
}

.drop_time_picker {
  cursor: pointer;
}

.drop_time_picker p:hover {
  color: #3eb2e7;
}

.drop_time_picker p.active {
  color: #3eb2e7;
}



.Notification_drop .rs-dropdown-menu {
  padding: 0 15px !important;
}

.text-danger {
  color: #f00 !important;
}

.avatar-text {
  height: 30px;
  width: 30px;
  padding: 8px;
  background: #2589f6;
  border-radius: 50%;
  text-transform: uppercase;
}

.error-input {
  border: 1px solid #f00 !important;
}

.text-muted {
  color: #7d8d91 !important;
}
.pageHeader-save {
  padding: 0 0 0 16px;
}
.pageHeader-save a {
  color: #fff;
  width: 50%;
}
.pageHeader-save a:hover,
a:focus {
  color: #fff !important;
  text-decoration: none !important;
}
.page-header-drop-down {
  background-color: transparent !important;
  color: #fff;
  width: 40%;
}
.page-header-drop-down a {
  width: 100%;
  margin-top: 4px;
}
.page-header-drop-down .rs-dropdown-toggle-caret {
  display: none !important;
}
.rs-dropdown-toggle .rs-dropdown-toggle-custom-title{
  img{
    margin: 5px !important;
  }
}
.page-header-drop-down .rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  padding: 0 !important;
}
.page-header-drop-down .rs-btn-subtle:focus,
.rs-btn-subtle.rs-btn-focus,
.rs-btn-subtle:hover {
  background-color: unset !important;
  color: #fff !important;
}
.page-header-drop-down .rs-dropdown-menu {
  margin-top: 16px;
  width: 150px;
}
.page-header-drop-down
  .rs-dropdown-menu
  > .rs-dropdown-item
  > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:focus {
  background-color: #e5f2ff !important;
  color: black !important;
}
.page-header-drop-down .rs-dropdown-item {
  border-radius: 5px !important;
}
.page-header-drop-down .rs-dropdown-menu {
  padding: 5px;
}
.selectBox-option {
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;

}
// .d-flex-start{
//   padding: 7px;
  
  
//   }
//   .d-flex-start:hover{
//     background-color: #e5f2ff;
//     cursor: pointer;
//     border-radius: 3px;
//   }
