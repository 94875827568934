.mainViewWhite {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  .mainViewWhite {
    flex-direction: column;
  }
}

.mainViewWhite-Content-body-inner {
  background-color: #ff00;
  height: calc(100% - 0px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 275px);
  margin-left: 275px;
}

@media only screen and (max-width: 1399.9px) {
  .mainViewWhite-Content-body-inner {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .mainViewWhite-Content-body-inner {
    width: calc(100% - 65px);
    margin-left: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .mainViewWhite-Content-body-inner {
    margin-left: 0;
    width: 100%;
    height: 100%;
  }
}

.mainViewWhite-Content-body-inner::-webkit-scrollbar {
  width: 2px;
}
.mainViewWhite-Content-body-inner::-webkit-scrollbar-track {
  background: #0000;
}
.mainViewWhite-Content-body-inner::-webkit-scrollbar-thumb {
  background: #fff0;
  border-radius: 10px;
}
.mainViewWhite-Content-body-inner::-webkit-scrollbar-thumb:hover {
  background: #fff0;
}

.mainViewWhite-Content {
  /* --background: url("../../assets/bg/1.jpeg") 0 0/100% 100% no-repeat; */
  /* --padding-top: calc(var(--padding-top) + var(--offset-top)); */
  color: #111 !important;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainViewWhite-Content-body {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #F0F0F0;
}

@media only screen and (max-width: 768px) {
  .mainViewWhite-Content-body {
    height: 100% !important;
    overflow-y: auto;
    padding-top: 65px;
    padding-bottom: 0px;
  }
}

ion-text {
  color: #222 !important;
}

ion-item {
  --background: #fff !important;
}

input {
  background-color: #fff !important;
}

ion-checkbox {
  --background: #fff;
}

ion-checkbox.md {
  border: 1px solid #bbb;
  border-radius: 3px;
  /* overflow: hidden; */
}

ion-checkbox.md .checkbox-icon {
  border: 1px solid #bbb;
  border-radius: 3px;
  /* overflow: hidden; */
}

ion-checkbox .checkbox-icon,
ion-checkbox .checkbox-icon {
  border-width: 1px;
  border-color: #aaa !important;
}

ion-label {
  --color: #222 !important;
}

ion-col,
ion-row {
  color: #222;
}

.ion-color-light {
  --ion-color-base: #f2f2f2 !important;
  --ion-color-contrast: #6fcf97 !important;
}

.btn-green-popup {
  background: #6fcf97 !important;
}

.bottom {
  padding: 5px 20px;
}

.HeaderTop {
  width: calc(100% - 275px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #fff2;
  margin-left: 275px;
}

@media only screen and (max-width: 1399.9px) {
  .HeaderTop {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .HeaderTop {
    width: calc(100% - 100px);
    margin-left: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .HeaderTop {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .HeaderTop {
    border-bottom: 1px solid #fff0;
    background-color: #0c2a31ee;
    border-radius: 15px;
    position: fixed;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    z-index: 6;
  }
}

.HeaderTop-timeline {
  color: #fff !important;
}
.HeaderTop-timeline-welcome {
  font-size: 14px;
  font-weight: 300;
}

.HeaderTop-timeline-Time {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 768px) {
  .HeaderTop-timeline-welcome {
    font-size: 10px;
    font-weight: 300;
  }

  .HeaderTop-timeline-Time {
    font-weight: bold;
    font-size: 10px;
    line-height: 19px;
  }
}

.HeaderTop-Search {
  width: 350px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  z-index: 99;
  position: relative;
}

.search_suggested.HeaderTop-Search {
  background: #ffffff;
  filter: drop-shadow(-1px 1px 4px rgba(0, 0, 0, 0.25));
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  color: #000;
  border-radius: 22px 22px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .HeaderTop-Search {
    display: none;
  }
}

.HeaderTop-Search_suggestions {
  background: #fff;
  border-radius: 0px 0px 27px 27px;
  max-height: 150px;
  padding-top: 1px;
  width: 100.6%;
  position: absolute;
  top: 38px;
  overflow-y: auto;
}

.HeaderTop-Search_suggestions::-webkit-scrollbar {
  width: 2px;
}
.HeaderTop-Search_suggestions::-webkit-scrollbar-track {
  background: #0000;
}
.HeaderTop-Search_suggestions::-webkit-scrollbar-thumb {
  background: #fff0;
  border-radius: 10px;
}
.HeaderTop-Search_suggestions::-webkit-scrollbar-thumb:hover {
  background: #fff0;
}

.HeaderTop-Search_suggestions_items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.HeaderTop-Search_suggestions_items:hover {
  background: #e5f2ff;
}

.HeaderTop-Search_suggestions_items_left {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  color: #304a50;
}

.HeaderTop-Search_suggestions_items_right h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #304a50;
}

.HeaderTop-Search_suggestions_items_right h6 span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #2589f6;
}

.HeaderTop-Search input {
  background-color: #0000 !important;
  height: 100%;
  width: 87%;
  border: none;
  color: #fff;
  padding: 10px;
  outline: none;
}

.search_suggested.HeaderTop-Search input {
  color: #000;
}

.HeaderTop-Search input::placeholder {
  color: #fff5;
}

.HeaderTop-Search svg {
  font-size: 20px;
}

.search_suggested.HeaderTop-Search svg {
  color: #000;
}

.HeaderTop_flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

/* @media only screen and (max-width: 600px) {
  .HeaderTop-Search svg {
    font-size: 14px;
  }
} */

.HeaderTop-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .HeaderTop-Search {
    width: 84%;
    top: 60px;
    display: flex;
    background: #fff;
    position: absolute;
    z-index: 99;
    color: #000;
  }

  .HeaderTop-Search input{
    color: #000;
  }

  .HeaderTop-Search input::placeholder {
    color: #0007;
  }
}