.loginPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  --background: url("../../assets/bg/1.jpeg") 0 0/100% 100% no-repeat;
  /* background-image: url("../../assets/bg/1.jpeg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.auth_Flex_card {
  width: 1000px;
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #0c2a31;
  overflow: hidden;
  border-radius: 35px;
}

@media only screen and (max-width: 1024px) {
  .auth_Flex_card {
    width: 100%;
    border-radius: 0px;
    height: 100vh;
  }
}

@media only screen and (max-width: 768px) {
  .auth_Flex_card {
    flex-direction: column;
    overflow-y: auto;
    padding-top: 1%;
    justify-content: flex-start;
  }
}

.bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.auth_Flex {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.auth_Flex_Left {
  width: 615px;
  min-height: 688px;
  background-color: #fff;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-right: -10px;
  padding: 3% 0;
}

@media only screen and (max-width: 768px) {
  .auth_Flex_Left {
    min-height: 70%;
    /* height: calc(100% - 265px); */
    height: auto;
    padding-top: 10px;
    width: 100%;
  }
}

.auth_Flex_Left h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  color: #304a50;
  margin-bottom: 10px;
  width: 400px;
}

@media only screen and (max-width: 768px) {
  .auth_Flex_Left h2 {
    width: 330px;
    margin: 5px auto;
  }
}

.width-login {
  width: 400px;
}

@media only screen and (max-width: 768px) {
  .width-login {
    width: 330px;
  }
}

.auth_social {
  width: 50px;
  height: 50px;
  margin: 5px 10px;
}
.social_loader {
  background: #0d2931;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_Flex_Left h2 span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #2589f6;
}

.auth_Flex_Right {
  width: 375px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  flex: 1;
}

.auth_logo {
  width: 200px;
  margin: 10px auto 30px;
}

@media only screen and (max-width: 768px) {
  .auth_Flex_Right {
    width: 100%;
    position: relative;
    /* height: 375px; */
    padding-top: 5px;
  }

  .auth_Flex_Right .auth_logo {
    height: 25px;
    margin: 5px auto 10px;
  }

  .auth_Flex_Right .auth_logo_lg {
    height: 150px;
    width: 100%;
    margin-bottom: -55px;
    margin-top: -20px;
  }
}

.auth_Flex_Right h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0 7px;
}

@media only screen and (max-width: 768px) {
  .auth_Flex_Right h2 {
    font-size: 18px;
    margin: 10px 35px 0;
    line-height: 1.5em;
  }
}

.auth_Flex_Right h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin: 10px 0 30px;
}

@media only screen and (max-width: 768px) {
  .auth_Flex_Right h3 {
    font-size: 13px;
    line-height: 1em;
  }
}

.auth_Form {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .auth_Form {
    width: 100%;
    padding: 0 15px;
  }
}

.auth_Form_title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  /* identical to box height */
  margin-bottom: 15px;
  color: #2589f6;
}

@media only screen and (max-width: 768px) {
  .auth_Form_title {
    font-size: 22px;
    line-height: 20px;
  }
}

.auth_Form_title_sub {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 30px;
  color: #304a50;
}

@media only screen and (max-width: 768px) {
  .auth_Form_title_sub {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
}

.authFormInput {
  width: 400px !important;
  height: 50px;
  border-radius: 0px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #0005;
  /* border-radius: 30px; */
}

@media only screen and (max-width: 768px) {
  .authFormInput {
    width: 330px !important;
  }
}

.authBtn {
  width: 400px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .authBtn {
    width: 330px;
    margin: 0px auto 0;
    font-size: 16px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }
}

.authBtnForget {
  text-align: right;
  width: 400px;
  cursor: pointer;
}

.spacedHeight {
  height: 10vh;
}

@media only screen and (max-width: 768px) {
  .spacedHeight {
    height: 3vh;
  }
}

.auth_Form_checkbox {
  width: 400px;
  border: 0;
  border-bottom: 1px solid #000;
  padding: 10px 0;
  outline: none !important;
  background-color: #fff !important;
  color: #000 !important;
}

@media only screen and (max-width: 768px) {
  .auth_Form_checkbox {
    width: 330px;
  }
}

.card_modal_input.login {
  background-color: #ededed;
  width: 255px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .card_modal_input.login {
    width: 130px;
    height: 33px;
    padding: 0 10px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

.login_otp {
  width: 42px !important;
  height: 42px;
  margin: 10px 10px 30px;
  border: 0px;
  background: #ededed !important;
  color: #000;
  text-align: center;
}

.err-msg {
  color: #f00;
  font-size: 15px;
}
