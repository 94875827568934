.TextAreaBox {
  width: 100%;
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  height: 100px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.TextAreaBox_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
  color: #304a50;
}

.TextAreaBox_input {
  box-sizing: border-box;
  border: 0px solid #e4e7f0;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff0 !important;
  outline: none;
  color: #304a50;
  z-index: 99;
}

.TextAreaBox_input:focus ~ .TextAreaBox_label,
.TextAreaBox.active  .TextAreaBox_label {
  top: -10px;
  font-size: 10px;
}