.full-width {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: end;
}
.Label-container {
  width: 34%;
  background-color: white;
  margin-top: 2rem;
  padding: 40px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  .bar_section {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 1rem;
    .qrcode_container {
      p {
        margin-top: 0.5rem;
        font-size: 13px;
        font-weight: 500;
      }
      .model_para {
        margin-top: 1rem;
        p {
          font-size: 13px;
          font-weight: 500;
          margin-top: 2px;
        }
      }
    }
  }
  .input-container {
    width: 48%;
    margin-top: 10px;
  }
  .switches-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin-top: 2.5rem;
    .switch {
      background-color: #d8e7ff;
      width: 48%;
      height: 43px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      padding: 22px 6px;

      p {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1399.9px) {
  .Label-container {
    width: 50%;
  }
}
@media only screen and (max-width: 800px) {
  .Label-container {
    width: 90%;
  }
  .switch {
    width: 100% !important;
  }
  .price {
    font-size: 25px !important;
  }
}
