.DashbaordPage {
  width: 100%;
  max-width: 1199px;
  height: 100%;
  background-color: #f000;
  padding: 15px;
  /* padding-left: 0; */
}

.quicktask-checkbox {
  cursor: pointer;
  width: 1rem;
  height: 1.5rem;
}

@media only screen and (max-width: 1599.9px) {
  .JobsOverview {
    padding: 15px 0;
    padding-left: 0;
    max-width: 990px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .JobsOverview {
    padding: 15px 0;
    padding-left: 0;
    max-width: 970px;
  }
}

@media only screen and (max-width: 600px) {
  .JobsOverview {
    padding: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .DashbaordPage {
    padding: 0;
    padding-left: 0;
  }
}

.btn_trans_profile {
  padding: 5px 10px;
  background-color: #00000010;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

@media only screen and (max-width: 600px) {
  .btn_trans_profile {
    padding: 3px 3px;
    background-color: #00000010;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 30px;
  }
}

.btn_trans_profile_img {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}

.btn_trans_profile p {
  color: #fff;
  font-size: 10px;
  margin-right: 7px;
  margin-top: -5px;
}

.btn-pin {
  position: absolute;
  padding: 0px;
  top: -10px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: #fec636;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.btn-white {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  margin: 0px 10px;
  border-radius: 30px;
  background-color: #fff;
  padding: 10px 15px;
}

@media only screen and (max-width: 600px) {
  .btn-white {
    font-size: 12px;
  }

  .btn-left {
    margin: 0px 0px;
    border-radius: 30px 0 0 30px;
    border-right: 1px solid #0001;
  }

  .btn-right {
    margin: 0px 0px;
    border-radius: 0px 30px 30px 0px;
    border-left: 1px solid #0001;
  }
}

.btn-white p,
.btn-white svg {
  color: #3eb2e7;
  font-weight: bold;
  margin: 0px 4px;
}

.DashbaordPage_body {
  width: 100%;
  background: #0000;
  padding: 15px 0px;
  min-height: 86%;
}

.DashbaordPage_body_row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .DashbaordPage_body_row.reverse {
    flex-direction: column-reverse;
  }
}

.DashbaordPage_row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 600px) {
  .DashbaordPage_body_row {
    flex-direction: column;
  }
}

.DashbaordPage_body_row_col {
  width: 50%;
  padding: 10px;
  /* background-color: #eb3434; */
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .DashbaordPage_body_row_col.mobile {
    padding: 0px;
  }
}

.DashbaordPage_row_col {
  flex: 1;
  padding: 10px;
  /* background-color: #eb3434; */
  height: 100%;
}

.DashbaordPage_row_col_2 {
  width: 65%;
}

.DashbaordPage_row_col_1 {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .DashbaordPage_body_row_col {
    width: 100%;
  }
}

.DashbaordPage_body_card {
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 12px;
}

.DashbaordPage_body_card_trans {
  width: 100%;
  padding: 10px 10px;
  background-color: #00000015;
  border-radius: 10px;
  margin-bottom: 10px;
  backdrop-filter: blur(25px);
}

.DashbaordPage_Header {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.1em;

  color: #304a50;
  /* padding-bottom: 10px; */
}

@media only screen and (max-width: 1024px) {
  .DashbaordPage_Header {
    font-size: 11px;
  }
}

.DashbaordPage_Header_wrapper .btn.btn-blue {
  border: 0px;
  background-color: #2589f6;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
}

.DashbaordPage_Header_wrapper .btn.btn-blue svg {
  color: #fff;
  margin-right: 5px;
}

.TURNOVER_text_wrapper {
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dfffe4;
  border-radius: 5px;
  margin-right: 5px;
}

.TURNOVER_text {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #36e052;
}

.TURNOVER_text_wrapper_wrong {
  padding: 4px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffdfdf;
  border-radius: 5px;
  margin-right: 5px;
}

.TURNOVER_text_wrong {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #ff0000;
}

.TURNOVER_circle_img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.TURNOVER_circle_img_cal {
  width: 25px;
  height: 25px;
  margin: 0 5px;
}

.MONTH_text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #304a50;
  flex: 1;
  text-align: center;
}

.MONTH_text.lg {
  font-size: 25px;
  padding-top: 12px;
}

@media only screen and (max-width: 1024px) {
  .MONTH_text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .MONTH_text {
    font-size: 14px;
  }
}

.divider-vertical-TURNOVER {
  width: 1px;
  height: 47px;
  background-color: #27333320;
}

.divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #27333320;
  margin: 5px 0;
}

.PROGRESS_item {
  padding: 16px 10px;
  background-color: rgba(211, 212, 218, 0.3);
  margin: 10px 0px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .PROGRESS_item {
    padding: 8px 10px;
  }
}

.PROGRESS_item_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PROGRESS_item_dot {
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  margin-right: 10px;
}

.PROGRESS_item_return {
  width: calc(100% - 10px);
  padding: 10px;
  background: #304a50;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PROGRESS_item_return h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.PROGRESS_item_return_steep {
  background: #e9ebea;
  border-radius: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #0b2a31;
  padding: 10px 20px;
  width: fit-content;
  margin-top: 10px;
}

.PROGRESS_item_text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #273333;
}

.PROGRESS_item_text_out_stock {
  color: #fff;
  background-color: #eb3434;
  padding: 2px 15px;
  border-radius: 15px;
}

.DashbaordPage_Header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
}

.DashbaordPage_Header_wrapper svg {
  color: #273333;
  font-size: 20px;
}

.chat_item {
  padding: 15px 10px;
  background-color: rgba(211, 212, 218, 0.3);
  margin: 10px 0px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat_item_img {
  width: 50px;
  height: 50px;
  background-color: rgba(254, 198, 54, 1);
  border-radius: 50%;
  position: relative;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_item_pin {
  width: 15px;
  height: 15px;
  background-color: #f00;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.chat_name_first {
  font-size: 20px;
  font-weight: bold;
}

.chat_circle_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_item_msg {
  flex: 1;
}

.row-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat_item_name {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #273333;
}

.chat_item_time {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #273333;
}

.chat_item_msg_txt {
  font-weight: 300;
  font-size: 14px;
  line-height: 2;
  color: #273333;
}

.QuickTasks_input {
  width: 100%;
  border: 0;
  outline: none;
  color: #273333;
}

.QuickTasks_input_body {
  height: 185px;
  width: 100%;
  overflow-y: auto;
}
.QuickTasks_input_body_loader .rs-placeholder-paragraph-rows p {
  background: rgba(255, 255, 255, 0.4) !important;
}
.QuickTasks_input_body::-webkit-scrollbar {
  width: 2px;
}
.QuickTasks_input_body::-webkit-scrollbar-track {
  background: #0003;
}
.QuickTasks_input_body::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}
.QuickTasks_input_body::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

.ChatNotification_input_body {
  height: 300px;
  width: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 1024px) {
  .ChatNotification_input_body {
    height: 226px;
  }
}

.ChatNotification_input_body::-webkit-scrollbar {
  width: 2px;
}
.ChatNotification_input_body::-webkit-scrollbar-track {
  background: #0000;
}
.ChatNotification_input_body::-webkit-scrollbar-thumb {
  background: #0002;
  border-radius: 10px;
}
.ChatNotification_input_body::-webkit-scrollbar-thumb:hover {
  background: #fff0;
}

.QuickTasks_h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  margin-left: 15px;
}
.QuickTasks_h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: rgba(39, 51, 51, 0.65);
  margin-left: 15px;
}
.QuickTasks_h5_crossed {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  margin-left: 15px;
  text-decoration: line-through;
}
.PopularItems_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ededed;
  border-radius: 50px;
  padding: 2px;
}
.PopularItems_no {
  border: 1px dashed #2589f6;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  margin: 0px 10px 0px 5px;
  color: #2589f6;
}
.PopularItems_name {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  flex: 1;
  color: #2589f6;
}
.PopularItems_pcs {
  background: #4f656a;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 10px;
  /* identical to box height */
  padding: 2px 5px;
  margin: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
.PopularItems_flex.d-flex-1 {
  padding: 0 10px;
}
.EstimatedJobs_cards {
  padding: 10px;
  border-radius: 10px;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.EstimatedJobs_cards img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.EstimatedJobs_cards h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  margin: 5px;
  color: #304a50;
}
.Manufacturers_color {
  width: 7px;
  height: 6px;
  background: #ff5879;
  margin-right: 7px;
  margin-top: 5px;
  margin-left: 10px;
}
.Manufacturers_name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #304a50;
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .Manufacturers_name {
    font-size: 10px;
  }
}

.Manufacturers_percentage {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #304a50;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0px 5px;
  background-color: #fff;
}

.dot-active {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0px 5px;
  background-color: #fff;
}

.drop_box_profile {
}

.unpaid_link {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 1024px) {
  .unpaid_link {
    font-size: 9px !important;
  }
}

.DashbaordPage_row::-webkit-scrollbar {
  width: 2px;
}
.DashbaordPage_row::-webkit-scrollbar-track {
  background: #0003;
}
.DashbaordPage_row::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}
.DashbaordPage_row::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

.dashboard_bottomTab {
  padding: 0px;
  width: 100%;
  background: #1a3c43;
  box-shadow: 0px -3px 7px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.dashboard_bottomTab_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  padding: 7px;
  color: #fff !important;
  text-decoration: none !important;
}

.dashboard_bottomTab_item.active {
  background-color: #fff2;
}

.dashboard_bottomTab_item p {
  color: #fff;
  font-size: 12px;
  margin-top: 4px;
  opacity: 0.6;
}

.btn-plus-mobile {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #2589f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .Dashboard_chatNotification {
    margin-bottom: 50px;
  }
}

.Dashboard_Progress_left {
  background-color: #fff;
  margin-top: -15px;
  height: 317px;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .Dashboard_Progress_left {
    display: flex;
    flex-direction: column-reverse;
  }
  .Dashboard_Progress_left .DashbaordPage_row_col_2 {
    width: 100%;
  }
  .Dashboard_Progress_left .DashbaordPage_row_col_1 {
    width: 98%;
  }
  .Progress_flex_column {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

.Progress_flex_column .Chart {
  width: 100%;
  margin-left: 10%;
  margin-top: 20%;
}

@media only screen and (max-width: 768px) {
  .Progress_flex_column .Chart {
    width: 40vw;
    margin-left: 1%;
    margin-top: 12%;
  }
}

/* GetStartedModal */
.GetStartedModal {
  width: 100vw;
  height: 100vh;
  background: rgba(20, 36, 52, 0.66);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GetStartedModal_inner {
  width: 400px;
  padding: 30px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  padding-top: 50px;
  position: relative;
}

.GetStartedModal_inner_img {
  width: 85px;
  height: 85px;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-40%, 0);
}

.GetStartedModal_inner h2 {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: rgba(48, 74, 80, 0.9);
}

.GetStartedModal_inner h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #0c2a31;
}

/*  */
.before_data{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0b2a3100;
}

.before_data.one img {
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
}

.before_data.two img {
  width: 100%;
  height: 272px;
  object-fit: contain;
  margin-bottom: -20px;
}

.before_data h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #304a50;
  margin-bottom: 5px;
}

.before_data h4.text-white {
  color: #fff;
}

.before_data h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #2589f6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.before_data h6 svg {
  font-size: 20px;
}