.flex-row_col{
    .searchDropDown .drop {
        display: none !important;
        position: absolute;
        border: 1px solid #afb9bb;
        width: 100%;
        top: 110%;
        z-index: 100;
        border-radius: 2px;
        padding: 0 5px 5px;
        background-color: white;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        border-radius: 3px;
        height: 300px !important;
        overflow-y: scroll !important;
    }
}