.status-color {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}
.StatusItem_left {
  width: 80% !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 5px 5px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #7d8d91;
    position: relative;
    padding-bottom: 2px;
  }
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #304a50;
  }

  .StatusItems_left_1 {
    width: 30%;
    border-right: 0.5px solid #304a50;
    margin-right: 7px;
  }
}
.StatusItems_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .lock {
    margin-right: 4rem;
    font-size: 1rem;
  }
}
.color-picker-container {
  display: flex;
  position: relative;
  .colorpicker {
    border: none;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    background-color: unset !important;
    z-index: 100;
    top: 6px;
    cursor: pointer;
  }
}
.asdf {
  width: 30px !important;
  padding: 8px !important;
  margin-right: 0.5rem;
}
