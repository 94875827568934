.table-header {
  display: flex;
  justify-content: space-between;
  background-color: #4F656AD9;
  color: #FFFFFF;
  text-align: center;
  align-items: center;
}
.d-flex-start{



}
.d-flex-start:hover{

}

.header-title {
  width: 100px;
}

.header-sub-title {
  font-size: 12px;
}

.table-body {
  display: flex;
}

.table-body {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0.5rem 0;
}

.table-body-data {
  padding: 0 0.75rem;
  width: 100px;
}