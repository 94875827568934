.minimalView-Content {
  --background: #fff !important;
  /* --padding-top: calc(var(--padding-top) + var(--offset-top)); */
  color: #111 !important;
}

ion-text {
  color: #222 !important;
}

ion-item {
  --background: #fff !important;
}

input {
  background-color: #fff !important;
}

ion-checkbox {
  --background: #fff;
}

ion-checkbox.md {
  border: 1px solid #bbb;
  border-radius: 3px;
  /* overflow: hidden; */
}

ion-checkbox.md .checkbox-icon{
  border: 1px solid #bbb;
  border-radius: 3px;
  /* overflow: hidden; */
}

ion-checkbox .checkbox-icon,
ion-checkbox .checkbox-icon {
  border-width: 1px;
  border-color: #aaa !important;
}

ion-label {
  --color: #222 !important;
}

ion-col,
ion-row {
  color: #222;
}

.ion-color-light {
  --ion-color-base: #f2f2f2 !important;
  --ion-color-contrast: #6FCF97 !important;
}

.btn-green-popup {
  background: #6FCF97 !important;
}
