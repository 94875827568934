.Add_Text_Templet_inner {
  width: 500px;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #fff;
  border-radius: 0px;
  overflow-x: hidden;
  color: #304a50;
  padding: 30px;
  position: relative;
  box-shadow: -3px 0px 8px rgb(0 0 0 / 25%);
  padding-bottom: 70px;
  overflow-y: auto;
  .CustomerContactModal_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 15px;
 width: 500px !important;
    background: #f9f9f9;
    border-top: 1px solid rgba(0, 0, 0, 0.0666666667);
}
}
.CustomerContactModal_btns {
  width: 500px !important;
}
.text_templet_model_body {
  padding: 0px 6 px;
}
.text_templet_model_contant {
  margin-top: 2rem;
}
.input-container {
  margin-top: 1.5rem;
}
.templet_textarea {
  margin-top: 1.5rem;
}
