.dropdown_receipts {
  padding-top: 7rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.drop-down-receipts {
  width: 100%;
  display: flex;
  justify-content: center;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding: 10px 55px 10px 10px !important;
  padding-bottom: 7px;
  background: rgb(37 137 246) !important;
  color: #fff !important;
  font-size: 16px !important;
  width: auto;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #ffff;
}
.rs-picker-menu.rs-picker-select-menu{
  padding-top: 0px !important;
  margin-top: 6px !important;
}


.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  // top: 7px;
  top: 10px;
  color: #ffff !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #ffff !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 12px !important;
  color: white !important;
  display: none !important;
}

.rs-picker-select
  .rs-picker-default
  .rs-picker-toggle-wrapper
  .rs-picker-placement-bottom-start {
  position: relative;
}

.rs-picker-toggle-caret::after {
  content: "";
  position: absolute;
  background-color: #ffff;
  width: 1px;
  height: 25px;
  // top: 1px;
  top: 0px;
  left: -18px;
}
.emailTemplatePageContainer {
  width: 100%;
  padding-left: 20%;

  .inputContainer {
    width: 100% !important;
  }
}
.emailTemplatePageSmall {
  width: 40% !important;

  .port {
    width: 30% !important;
  }
  .emailTemplatePageSmallSelect {
    width: 50% !important;
  }
  .connection-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    .connection {
      background-color: #d3edd6;
      width: 60%;
      padding: 5px;
      border-radius: 5px;
      p {
        color: #077a11;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.emailTemplateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .emailTemplateTextEditor {
    width: 100%;

    min-height: 25rem !important;
    .fr-second-toolbar {
      display: none !important;
    }
    .fr-wrapper .show-placeholder {
      height: 17rem !important;
    }
  }
  .emailTemplatePage {
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    width: 60%;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 1.5rem;

    .heading {
      font-size: 20px;
    }
    .form {
      display: flex;
      margin-top: 1.5rem;
      justify-content: space-between;
      gap: 10px 0;
      flex-wrap: wrap;
      .inputContainer {
        width: 48%;
      }

      .testEmail-input {
        width: 85%;
      }
      .testEmail-btn {
        background-color: #2589f6;
        height: 43px;
        width: 42px;
        border-radius: 5px;
      }

      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        border: 1px solid #a0adb3;
        border-radius: 3px;
        height: 43px !important;
        padding: 10px;
        background-color: #fff0 !important;
        outline: none;
        color: #304a50;
        z-index: 99;
        width: 100%;
        height: 100% !important;
        display: flex;
        border-radius: 5px;
        align-items: center;

        p {
          color: #a0adb3;
          font-size: 15px;
        }
      }

      .btn {
        //   border: 2px solid gray;
        color: gray;
        background-color: white;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer !important;

        &:hover {
          background-color: red;
        }
      }

      .upload-btn-wrapper input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 1399.9px) {
  .Label-container {
    width: 50%;
  }
}
@media only screen and (max-width: 950px) {
  .emailTemplatePage {
    width: 90% !important;
  }
  .testEmail-input {
    width: 80% !important;
  }
}
