.align-left {
  text-align: left;
}
.smallRecepit {
  width: 60% !important;
  padding: 10px 60px 60px 60px !important;
  height: 1500px !important;
}
.tampletReceipt {
  background-color: #26252542 !important;
}
.receiptsPage {
  width: 100%;
  height: 1350px;
  width: 831px;
  background-color: #fff;
  margin-top: 1rem;
  padding: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  // .receiptsPage-body {
  //   border-radius: 10px;
  // }
  .small-receiptsPage-header {
    height: 400px !important;
    margin-bottom: 1rem !important;
  }
  .receiptsPage-header {
    margin-bottom: 3rem;
    height: 300px;
    padding: 0 10px;

    .small-receiptsPage-header-cols {
      flex-direction: column;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    .receiptsPage-header-cols {
      height: 100%;

      &:first-child {
        display: flex;
        align-items: center;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
      .rs-uploader {
        width: 200px;
        height: 100px;

        button {
          width: 200px;
          height: 100px;
          border: 2px dashed black;
          background-color: #f6f6f6;
          .logo-layout {
            h2 {
              color: #ddd9d9;
              span {
                color: rgb(55, 55, 55);
              }
            }
            p {
              color: rgb(55, 55, 55);
              font-weight: bold;
            }
          }
        }
      }
    }
    .ImgDlt {
      background-color: unset;
      font-size: 20px;
      color: #4f656a;
    }
    .smallRecepit-logomain {
      display: block;
    }
    .largeRecepit-logomain {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .fr-box.fr-document .fr-wrapper {
    background-color: #fff !important;
  }
  .fr-box.fr-document .fr-wrapper .fr-element {
    width: 20cm !important;
  }

  .compenyDetails-1 {
    .compenyDetails-text {
      font-weight: bold;
      line-height: 10px;
      &:first-child {
        font-weight: normal;
        font-size: smaller;
      }
    }
  }
  .compenyDetails-2 {
    .compenyDetails-text {
      font-weight: 400;
      line-height: 10px;
      color: #b1b1b1;
      font-size: larger;
    }
  }

  .receiptsPage-body-heading {
    color: rgb(55, 55, 55);
    margin-bottom: 2rem;
    padding: 0 10px;
  }
  .receiptsPage-body {
    background-color: #f0f0f0;
    width: 100%;
    // height: 200px;
    position: relative;
    border-radius: 10px;

    &::before {
      content: "Salutation";
      position: absolute;
      background-color: #78898d;
      color: #fff;
      padding: 3px 5px;
      border-radius: 3px;
      font-size: small;
      top: -22px;
      left: 10px;
    }
    .fr-second-toolbar {
      display: none !important;
    }
    .fr-box.fr-basic.fr-top .fr-wrapper {
      min-height: 80px;
    }
  }
  .receiptsPage-body_body {
    background-color: #f0f0f0;
    width: 100%;
    // height: 200px;
    position: relative;
    border-radius: 10px;

    &::before {
      content: "Term & Condition";
      position: absolute;
      background-color: #78898d;
      color: #fff;
      padding: 3px 5px;
      border-radius: 3px;
      font-size: small;
      top: -22px;
      left: 10px;
    }
    .fr-second-toolbar {
      display: none !important;
    }

    .fr-box.fr-basic.fr-top .fr-wrapper {
      min-height: 200px;
    }
  }
}

.qr-code {
  margin-top: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  height: 61px;
  width: 360px;
  background-color: #ededed;
  padding: 10px;
  border-radius: 6px;
  .qr-receipt {
    color: #304a50;
    font-family: "Roboto";
    font-size: 13;
  }
}

.pageFooter {
  background-color: #f6f6f6;
  margin-top: 3rem;
  text-align: center;

  h1 {
    font-weight: 400;
    color: #e6e8e9;
  }
}
.pageBody {
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 85px 0;

  h1 {
    font-weight: 400;
    color: #e6e8e9;
    font-size: 100px;
  }
}

.smallReceiptBarcode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AiOutlineCloudUpload {
  font-size: 100px;
}
.logo-layout-tamplet {
  background-color: #fff;
}
.uplodCustomText {
  font-size: 22px;
  color: #4f656a;
}
