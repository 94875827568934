.account_layout {
    width: 100%;
    padding: 125px 0px 0px 35px;

    .reset_section_container {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 45%;
        margin-bottom: 1rem;
        padding: 40px 40px;

        .reset_hedding {
            h6 {
                font-weight: 400;
                font-size: 20px;
                margin-bottom: 1rem;
            }

            p {
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 0.5rem;
            }
            .reset_checkbox{
                margin-bottom: 0.5rem;
                .rs-checkbox-wrapper{
                    left: 3px !important;
                }
            }
            .reset_input{
                margin-bottom: 0.8rem;
              
            }
            .resetbtn{
                text-align: center;
                margin: 2rem 0px;

                button{
                    width: 181px;
                    height: 45px;
                    border-radius: 42px;
                    background: #A0ADB3;
                    cursor: pointer;
                    color: rgba(255, 255, 255, 0.5);

                }
            }
        }
    }
}

@media only screen and (max-width: 1199.9px) {
    .TextTemplate {
      padding: 15px 0;
      padding-left: 0;
      max-width: 970px;
    }
  }
  