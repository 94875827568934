.InputBox {
  width: 100%;
  border: 1px solid rgba(48, 74, 80, 0.39);
  border-radius: 5px;
  position: relative;
  height: 45px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #304a50;
}

.InputBox.disabled {
  border: 1px solid #A0ADB3;
}
.InputBox.disabled input{
  color: #A0ADB3;
  cursor: no-drop;
}

.InputBox_label {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.07em;
  color: #304a50;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px;
}

.InputBox.disabled .InputBox_label {
  color: #A0ADB3;
}

.InputBox_input {
  box-sizing: border-box;
  border: 0px solid #A0ADB3;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff0 !important;
  outline: none;
  color: #304a50;
  z-index: 99;
}

.InputBox_input:focus ~ .InputBox_label,
.InputBox.active .InputBox_label {
  top: -10px;
  font-size: 10px;
}
