.SupplierContact {
  width: 100%;
  max-width: 1199px;
  height: 100%;
  background-color: #f000;
  padding: 15px;
  /* padding-left: 0; */
}

@media only screen and (max-width: 1599.9px) {
  .SupplierContact {
    padding: 15px 0;
    padding-left: 0;
    max-width: 990px;
  }
}

@media only screen and (max-width: 1199.9px) {
  .SupplierContact {
    padding: 15px 0;
    padding-left: 0;
    max-width: 970px;
  }
}

@media only screen and (max-width: 600px) {
  .SupplierContact {
    padding: 0;
    padding-left: 0;
  }
}

.SupplierContact_body {
  width: 100%;
  background: #fff;
  padding: 0px 0px;
  min-height: 80vh;
  max-height: 80vh;
  border-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .SupplierContact_body {
    min-height: 75vh;
    max-height: 75vh;
    margin-bottom: 55px;
  }
}

.SupplierContact_body_top {
  padding: 7px 7px;
  border-bottom: 1px solid #0002;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.SupplierContact_body_top_btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #ededed;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .SupplierContact_body_top_btn {
    width: 66px;
    height: 26px;
    padding: 7px;
  }
}

.SupplierContact_body_top_btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.SupplierContact_body_top_search {
  background-color: #ededed;
  border-radius: 30px;
  padding: 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #304a50;
  font-size: 18px;
  margin: 0px 7px;
}

.ConvertTicket_modal_search.SupplierContact_body_top_search {
  font-size: 15px;
}

.SupplierContact_body_top_search input {
  border: none !important;
  background-color: #0000 !important;
  height: 35px !important;
  font-size: 14px;
  outline: none !important;
}

.ConvertTicket_modal_search.SupplierContact_body_top_search input {
  height: 25px !important;
  font-size: 12px;
}

.SupplierContact_top_tabs {
  position: absolute;
  background: #ffffff;
  left: 50%;
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px #0002;
  margin-top: -55px;
}

@media only screen and (max-width: 1599.9px) {
  .SupplierContact_top_tabs {
    left: 45%;
  }
}

@media only screen and (max-width: 1199.9px) {
  .SupplierContact_top_tabs {
    left: 40%;
  }
}

.SupplierContact_top_tabs_item {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.SupplierContact_top_tabs_item_active {
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  background-color: #2589f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.SupplierContact_jobTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 20px;
}

.SupplierContact_jobTabs_inner {
  flex: 1;
  height: 45px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(0px);
  border-radius: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .SupplierContact_jobTabs_inner {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.SupplierContact_jobTabs_mobile_scroll {
  /* background-color: #000; */
  width: calc(100vw - 20px);
  /* padding: 10px; */
  /* margin-right: 13px; */
  overflow-x: auto;
}

.SupplierContact_jobTabs_inner_border {
  height: 35px;
  width: 1px;
  background-color: #ffffff;
  margin: 0px 5px;
}

.SupplierContact_jobTabs_inner_item {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 10px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.SupplierContact_jobTabs_inner_item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.SupplierContact_jobTabs_inner_item_active {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #0f0;
  padding: 15px;
  height: 55px;
  border-radius: 15px;
  background-color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #304a50;
  cursor: pointer;
}

@media only screen and (max-width: 1599.9px) {
  .SupplierContact_jobTabs_inner_item_active {
    padding: 10px;
  }
}

.SupplierContact_jobTabs_inner_item_active img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.SupplierContact_jobTabs_inner_item_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.SupplierContact_jobTabs_inner_item_circle_text {
  width: 100px;
}

.SupplierContact_jobTabs_inner_item_text {
  text-align: left;
}

.SupplierContact_returnDevices {
  width: 180px;
  height: 45px;
  background: #ffffff;
  backdrop-filter: blur(0px);
  border-radius: 15px;
  box-shadow: 0px 5px 10px #0002;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SupplierContact_returnDevices_img {
  width: 47.71px;
  height: 59px;
  margin-top: -15px;
  margin-right: 7px;
}

.SupplierContact_returnDevices_text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #4f656a;
}

.SupplierContact_returnDevices_number {
  border-radius: 50%;
  background: #304a50;
  margin: 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #ffffff;
  width: 35px;
  height: 35px;
}

.SupplierContact_returnDevices_number_text {
  width: 100px;
  text-align: center;
}

.SupplierContact_body_bottom {
  padding: 0px 15px;
  max-height: 74vh;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .SupplierContact_body_bottom {
    max-height: 54vh;
    min-height: 45vh;
    padding: 0px;
  }
}

.SupplierContact_body_bottom::-webkit-scrollbar {
  width: 2px;
}
.SupplierContact_body_bottom::-webkit-scrollbar-track {
  background: #0003;
}
.SupplierContact_body_bottom::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 10px;
}
.SupplierContact_body_bottom::-webkit-scrollbar-thumb:hover {
  background: #fff2;
}

.SupplierContact_body_bottom_flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

//

.SupplierContactItems {
  width: 100%;
  padding: 5px;
  background-color: #ededed;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #273333;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 14px;
}

.SupplierContactItems_left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 5px 5px;
}

.SupplierContactItems_left img {
  width: 50px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
}

.SupplierContactItems_left img.small {
  width: 20px;
  height: 20px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 30px;
}

.SupplierContactItems_left p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7d8d91;
  position: relative;
  padding-bottom: 2px;
}

.SupplierContactItems_left p span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #fe7c03;
  position: absolute;
  bottom: 0;
  right: 0;
}

.SupplierContactItems_left h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #304a50;
}

.SupplierContactItems_left_1 {
  flex: 1;
  border-right: 0.5px solid #304a50;
  margin-right: 7px;
}

.SupplierContactItems_left_2,
.SupplierContactItems_left_3 {
  flex: 1.5;
}

.SupplierContactItems_left_4 {
  flex: 1.5;
}

.SupplierContactItems_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.SupplierContactItems_right h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #304a50;
  border-radius: 10px;
  background: #304a50;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 0px 15px;
  min-width: 90px;
}

.SupplierContactItems_right p {
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #304a50;
  margin: 0px 15px;
  min-width: 90px;
  text-align: center;
}

//
.SupplierContactModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20,36,52,.40);
  backdrop-filter: blur(0px);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SupplierContactModal_inner {
  width: 850px;
  min-height: 710px;
  max-height: 710px;
  background-color: #fff;
  border-radius: 16px;
  overflow-x: hidden;
  color: #304a50;
  padding: 30px;
}

.SupplierContactModal_inner_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.SupplierContactModal_inner_top h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: rgba(48, 74, 80, 0.9);
}

.SupplierContactModal_inner_top .close {
  font-size: 20px;
  cursor: pointer;
}

.SupplierContactModal_inner_flex_start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.SupplierContactModal_inner_blueShow {
  background: #d8e7ff;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SupplierContactModal_inner_blueShow img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.SupplierContactModal_inner_blueShow .f1 {
  flex: 1;
  padding-left: 5px;
}

.SupplierContactModal_inner_blueShow .f1 h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #304a50;
}

.SupplierContactModal_inner_blueShow .f1 p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #304a50;
}

.SupplierContactModal_tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #27333320;
}

.SupplierContactModal_tab {
  padding: 7px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #a0adb3;
  cursor: pointer;
  border-bottom: 3px solid #fff;
}

.SupplierContactModal_tab.active {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #2589f6;
  border-bottom: 3px solid #2589f6;
}

.SupplierContactModal_tab_body {
  width: 100%;
  min-height: 260px;
  max-height: 260px;
  overflow-y: auto;
}

.SupplierContactModal_tab_body::-webkit-scrollbar {
  width: 2px;
}
.SupplierContactModal_tab_body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.SupplierContactModal_tab_body::-webkit-scrollbar-thumb {
  background: #2589f6;
  border-radius: 2px;
}
.SupplierContactModal_tab_body::-webkit-scrollbar-thumb:hover {
  background: #2589f6;
}

.SupplierContactModal_tab_body_header {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #304a50;
  padding: 10px 0px;
}

.SupplierContactModal_inner_upload {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px dashed #2589f6;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #2589f6;
  margin-top: 10px;
  cursor: pointer;
}

.SupplierContactModal_inner_uploaded_img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 2px solid #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.SupplierContactModal_inner_uploaded_img:hover {
  border: 2px solid #2589f6;
}

.SupplierContactModal_inner_uploaded_img p {
  display: none;
}

.SupplierContactModal_inner_uploaded_img:hover p {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #2589f6;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 2px;
}

.SupplierContactModal_inner_uploaded_img .SupplierContactModal_inner_flex_spaced {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: #8a8c8c;
  text-shadow: 0px 0px 10px #000;
}

.SupplierContactModal_inner_uploaded_img .SupplierContactModal_inner_flex_spaced .pointer_blue {
  color: #2589f6 !important;
  cursor: pointer;
  font-size: 20px;
}
